<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li>Doctors</li>
      </ul>
    </div>
</div>





<section class="doctors-details-list mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="details-item">
                    <img class="dr_img" src="assets/img/dr_two.jpg">
                    <h2>Dr. Ajina M 
                    </h2>
                    <span>MBBS DDVL 
                    </span>
                    
                  
                </div>
            </div>
            <div class="col-lg-3 mt-4">
              <h5 style="color: #20618E;">Other Doctors</h5>
                <div class="similar-doctors mt-4">
                  <a style="text-decoration: none;" routerLinkActive="active" [routerLink]="['/doctor-nikhila-detail']">
                    <div class="similar-doc-item">
                      <img src="assets/img/dr_one.jpg" class="img-fluid">
                      <h5>Dr. Bishurul Hafi NA</h5>
                      <span style="color:#000000B2 ;">MBBS, MD (DVD), DNB, FECSM</span>
                    </div>
                  </a>
                 <!-- <a style="text-decoration: none;"  href="">
                    <div class="similar-doc-item mt-5">
                      <img src="assets/img/doc3.png" class="img-fluid">
                      <h5>Dr. Leslie Alexander</h5>
                      <span>MBBS, MD (DVD), DNB, FECSM</span>
                    </div>
                  </a>
                  <a style="text-decoration: none;"  href="">
                    <div class="similar-doc-item mt-5">
                      <img src="assets/img/doc4.png" class="img-fluid">
                      <h5>Dr. John Alexander</h5>
                      <span>MBBS, MD (DVD), DNB, FECSM</span>
                    </div>
                    </a> -->
  
                </div>
            </div>
        </div>
    </div>
  </section>

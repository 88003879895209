<div class="bread-crumb mt-5">
    <div class="container">
        <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <li><a routerLinkActive="active" [routerLink]="['/service-list']">Services</a></li>
            <li>Cosmetic Treatment</li>
        </ul>
    </div>
</div>




<section class="service-details-area sub-title">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 mt-5">
                <div class="service-details-content">
                    <img src="assets/img/Cosmetic Treatments.jpg" class="img-fluid">
                    <h2>Cosmetic Treatment</h2>
                    <p>
                        Skin and face - We expertise ourselves in providing aesthetic and cosmetic treatments like
                        Chemical peeling, Botox, Filler, Non surgical face lift, PRP/Vampire facial, Medi facial, Skin
                        lightening treatments, Stretch marks, Acne and Acne scar, Keloid and hypertrophic scar,
                        Injection lipolysis, Eye wrinkles and dark circles, (Wart, milia, skin tag, DPN)- removal, Mole
                        removal, Ear lobe correction, Sebaceous cyst excision, Vitiligo surgery, Mesotherapy face.
                    </p>
                    <p>
                        Hair treatment- Apart from skin treatments we provide extensive care for all your hair care
                        problems like PRP, GFC, Mesotherapy, Hair transplant, Laser hair removal.
                    </p>

                </div>


                <div class="other-datas deskhide">
                    <div class="row">
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/ACNE SCARRING.png"
                                class="img-fluid">
                            <h5>ACNE SCARRING</h5>
                        </div>
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/Carbon peeling.png"
                                class="img-fluid">
                            <h5>Carbon peeling</h5>
                        </div>
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/Chemical Peeling.png"
                                class="img-fluid">
                            <h5>Chemical Peeling</h5>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/De Taning & Glow.png"
                                class="img-fluid">
                            <h5>De Taning & Glow</h5>
                        </div>
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/LASER HAIR REMOVAL.png"
                                class="img-fluid">
                            <h5>LASER HAIR REMOVAL</h5>
                        </div>
                        <div class="col-lg-4 mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/Skin tag - Wart- DPN Removal.png"
                                class="img-fluid">
                            <h5>Skin tag - Wart- DPN Removal</h5>
                        </div>

                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/tattoo removal.png"
                                class="img-fluid">
                            <h5>tattoo removal</h5>
                        </div>
                    </div>
                </div>


            </div>








            <div class="col-lg-4 mt-5">
                <h5 style="color: #20618E; font-size: 20px;">Other Services</h5>
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/dermatology']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/ACNE.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>General Dermatology</h5>

                        </div>

                    </div>
                </a>

                <a class="service_tag" routerLinkActive="active" [routerLink]="['/hair-clinic']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/Hair Transplant.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Hair Clinic</h5>

                        </div>


                    </div>
                </a>

                <a class="service_tag" routerLinkActive="active" [routerLink]="['/psychodermatology']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY (2).jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Psychodermatology & Palliative Dermatology</h5>

                        </div>


                    </div>
                </a>


                <a class="service_tag" routerLinkActive="active" [routerLink]="['/sexually-disease']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/5.-What is an unconsummated marriage.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>STD (Sexually Transmitted Disease)</h5>

                        </div>


                    </div>
                </a>

                <a class="service_tag" routerLinkActive="active" [routerLink]="['/amore-centre']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/amore.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Amore Centre For Sexual Medicine And Relationship Therapy</h5>

                        </div>


                    </div>
                </a>

            </div>
        </div>

        <div class="row">
            <div class="other-datas mobhide">
                <div class="row">
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/ACNE SCARRING.png"
                            class="img-fluid">
                        <h5>ACNE SCARRING</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/Carbon peeling.png"
                            class="img-fluid">
                        <h5>Carbon peeling</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/Chemical Peeling.png"
                            class="img-fluid">
                        <h5>Chemical Peeling</h5>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/De Taning & Glow.png"
                            class="img-fluid">
                        <h5>De Taning & Glow</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/LASER HAIR REMOVAL.png"
                            class="img-fluid">
                        <h5>LASER HAIR REMOVAL</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/Skin tag - Wart- DPN Removal.png"
                            class="img-fluid">
                        <h5>Skin tag - Wart- DPN Removal</h5>
                    </div>

                </div>

                <div class="row mt-4">
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Cosmetic Treatments/tattoo removal.png"
                            class="img-fluid">
                        <h5>tattoo removal</h5>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<section class="faq-home mt-50">
    <div class="container">
        <h2 class="text-center mt-4">FAQ</h2>

        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        1. What services does Revive Skin Clinics offer?
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Revive Skin Clinics provides a wide range of services including general dermatology for skin
                        conditions like acne, eczema, and psoriasis, cosmetic treatments such as Botox, dermal
                        fillers, and chemical peels, specialized hair clinic services for hair loss and scalp
                        conditions, palliative dermatology for managing symptoms associated with chronic skin
                        conditions, and confidential consultations and treatments for sexually transmitted diseases
                        (STDs).
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2. What general dermatology services are available at Revive Skin Clinics? </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Our general dermatology services cover a wide spectrum, including diagnosis and treatment
                        for various skin conditions such as acne, eczema, psoriasis, and skin cancer screenings. We
                        also offer personalized skincare routines and advice for maintaining healthy skin. </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        3. Can lifestyle changes help manage acne?
                    </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Yes, certain lifestyle changes can significantly help manage acne. Maintaining a consistent
                        skincare routine, avoiding heavy cosmetics, eating a balanced diet, and managing stress are
                        crucial. Our dermatologists at Revive Skin Clinics can guide you on effective lifestyle
                        adjustments alongside medical treatments for optimal results. </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        4. What specialized services does the hair clinic at Revive Skin Clinics offer? </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Our hair clinic specializes in diagnosing and treating hair loss, scalp conditions, and
                        providing hair restoration solutions. We offer treatments such as PRP (Platelet-Rich Plasma)
                        therapy, hair transplants, and medical management for conditions like alopecia. </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        5. Does Revive Skin Clinics provide palliative dermatology services? </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Yes, we offer palliative dermatology services aimed at providing relief and improving the
                        quality of life for patients with chronic or terminal skin conditions. Our compassionate
                        team works closely with patients and caregivers to manage symptoms and maintain skin health.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        6. What STD screening and treatment options are available at Revive Skin Clinics? </button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        We provide confidential STD screenings for a range of sexually transmitted infections, including
                        HIV, syphilis, gonorrhea, and chlamydia. Our clinic offers comprehensive testing, prompt
                        diagnosis, and personalized treatment plans to ensure the health and well-being of our patients.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        7. How safe are the cosmetic procedures at Revive Skin Clinics? </button>
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        The cosmetic procedures at Revive Skin Clinics are performed by highly trained professionals
                        using state-of-the-art equipment and techniques. Patient safety is our top priority, and we
                        adhere to strict safety protocols to ensure all treatments are safe and effective. </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        8. What treatments are available at Revive Hair Clinic for hair loss? </button>
                </h2>
                <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Revive Hair Clinic offers a range of hair loss treatments including PRP (Platelet-Rich Plasma)
                        therapy, hair transplantation, laser therapy, and medication to stimulate hair growth and
                        restore hair density.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        9. Can children receive dermatology care at Revive? </button>
                </h2>
                <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Yes, Revive Skin Clinics provides dermatology care for patients of all ages, including children,
                        for a variety of skin conditions such as eczema, rashes, and acne.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        10. How effective is laser hair removal at Revive Skin Clinics? </button>
                </h2>
                <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Laser hair removal at Revive Skin Clinics is highly effective for reducing unwanted hair.
                        Multiple sessions are usually required to achieve optimal results, and many patients experience
                        long-term hair reduction, enjoying smoother skin without the need for frequent shaving or
                        waxing. </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseEone" aria-expanded="false" aria-controls="collapseEone">
                        11. How long do treatments typically take? </button>
                </h2>
                <div id="collapseEone" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        The duration of treatments at Revive Skin Clinics varies depending on the procedure. Simple
                        treatments like chemical peels or microdermabrasion may take about 30-45 minutes, while more
                        complex procedures such as laser therapies or hair transplants can take several hours. The
                        clinic ensures efficient scheduling to minimize wait times.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTone" aria-expanded="false" aria-controls="collapseTone">
                        12. What is the cost of treatments at Revive Skin Clinics? </button>
                </h2>
                <div id="collapseTone" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        The cost of treatments at Revive Skin Clinics varies based on the specific procedure and
                        individual requirements. The clinic offers competitive pricing and transparent cost breakdowns
                        during the initial consultation. Patients can also inquire about available packages and
                        financing options to make their treatments more affordable.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThreeOne" aria-expanded="false" aria-controls="collapseThreeOne">
                        13. What should I expect during my first consultation? </button>
                </h2>
                <div id="collapseThreeOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        During your first consultation at Revive Skin Clinics, you will undergo a thorough skin analysis
                        conducted by a qualified dermatologist. This includes discussing your medical history, current
                        skin concerns, and desired outcomes. Based on this information, a personalized treatment plan
                        will be created to address your specific needs effectively. </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFourOne" aria-expanded="false" aria-controls="collapseFourOne">
                        14. Are the treatments at Revive Skin Clinics safe? </button>
                </h2>
                <div id="collapseFourOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Yes, the treatments at Revive Skin Clinics are highly safe. The clinic employs experienced
                        dermatologists and certified professionals who use state-of-the-art equipment and follow
                        stringent safety protocols. All procedures are conducted in a sterile environment, ensuring
                        patient safety and optimal results.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFiveOne" aria-expanded="false" aria-controls="collapseFiveOne">
                        15. What makes Revive Skin Clinics unique compared to other dermatology clinics? </button>
                </h2>
                <div id="collapseFiveOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Revive Skin Clinics stands out due to our comprehensive approach, advanced technology,
                        personalized care, and our dedicated team of specialists who provide expert treatment across a
                        wide range of dermatological and cosmetic concerns.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="faq-home mt-50">
    <div class="container">
        <h2 class="text-center mt-4">FAQ</h2>

        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        1. What cosmetic treatments are available at Revive Skin Clinics? </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Revive Skin Clinics in Kozhikode offers a wide range of cosmetic treatments, including acne scarring,carbon peeling,chemical peeling,de taning and glow,laser hair removal,skin tag-wart-dpn removal,tattoo removal. We use the latest technologies and techniques to ensure effective and safe results.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2. How do I know which cosmetic treatment is right for me?                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        At Revive Skin Clinics, our dermatologists conduct a thorough consultation to assess your skin concerns, medical history, and aesthetic goals. Based on this evaluation, we recommend the most suitable cosmetic treatments to achieve your desired results safely and effectively.</div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        3. Can I combine multiple cosmetic treatments in one visit? </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Yes, it is often possible to combine multiple cosmetic treatments in one visit at Revive Skin Clinics. Our dermatologists will evaluate your skin and aesthetic goals to create a personalized treatment plan that may include a combination of procedures to achieve the best results efficiently.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        4. Are there any pre-treatment instructions I should follow? </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Before your cosmetic treatment at Revive Skin Clinics, our dermatologists will provide specific pre-treatment instructions. Generally, it is advised to avoid sun exposure, discontinue certain skincare products, and refrain from taking blood-thinning medications to minimize the risk of bruising and complications.    
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        5. How can I maintain the results of my cosmetic treatments?                    </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        To maintain the results of your cosmetic treatments, follow the skincare regimen recommended by your dermatologist at Revive Skin Clinics. This may include using high-quality skincare products, protecting your skin from sun exposure, and scheduling regular follow-up treatments to prolong the effects of your initial procedures.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
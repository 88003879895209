import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private toastr: ToastrService) { }

  // publishEvent(){
  //   this.event.publishSomeData("event_publish")                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
  // }


  showSuccess(message:any) {
    this.toastr.success(message);
  }

  showToastError(message:any) {
    this.toastr.error(message);
  }

  processError(error:any) {
    if (typeof error == "object") {
        var jsonParsed = JSON.parse(error._body);
        if (jsonParsed.message) {
            this.showToastError(jsonParsed.message)
        } else if (jsonParsed) {
            var msg = '';
            (jsonParsed)

            for (var prop in jsonParsed) {
                (jsonParsed[prop])
                jsonParsed[prop].forEach((element:any) => {
                    if (msg == '') {
                        msg += element;
                    } else {
                        msg += '</br>' + element;
                    }
                });
            }

            this.showToastError(msg)
        } else {
            this.showToastError('Something went wrong')
        }
    }

}
}

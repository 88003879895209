<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li>Blogs</li>
      </ul>
    </div>
</div>

<!-- <section class="blog-details">
   <div class="container">
           
            <div class="row">
                <div class="col-lg-8 mt-5" *ngFor="let team of blogList.slice(0,1); ;let i=index;">
                    <div class="blog-details">
                        <a   [routerLink]="['/blog-detail/',team.id]"><img src="{{team.image_url}}" class="img-fluid"></a>
                        <h2>{{team.title}}</h2>
                        <span>{{team.created_at | date :'dd MMM yyyy'}}</span>
                        <p [innerHTML]="(team.description | slice: 0:500) ">
                        <a [routerLink]="['/blog-detail/',team.id]"
                                        class="index_btn_bs">View More</a>
                        
                    </div>
                </div>
                
                <div class="col-lg-4 mt-5">
                    <h5>Related Blogs</h5>
                    <div class="blog-right d-flex mt-5" *ngFor="let bl of blogList.slice(1,4); ;let i=index;">
                        <div class="blog-right-img">
                            <a   [routerLink]="['/blog-detail/',bl.id]"><img src="{{bl.image_url}}"></a>
                        </div>
                        <div class="blog-right-details">
                            <h5>{{bl.title}}</h5>
                            <span>{{bl.created_at | date :'dd MMM yyyy'}}</span>
                        </div>

                    </div>
              
                </div>
            </div>
        </div> 
</section> -->




<section class="blog-list-page mt-5">
    <div class="container">
        <h2 class="text-center">Our Latest Blogs</h2>

        <div class="row">
            <div class="col-lg-4 mt-5 blog-detail" *ngFor="let team of blogList | paginate: { itemsPerPage: 6, currentPage: p }">
                <a   [routerLink]="['/blog-detail/',team.id]"><div class="blog-list-item">
                    <img src="{{team.image_url}}" class="img-fluid">
                    <h5>{{team.title}} </h5>
                    <span>{{team.created_at | date :'dd MMM yyyy'}}</span>
                </div>
                </a>
            </div>
           
        </div>

        <!-- <div class="row">
            <div class="col-lg-4 mt-5">
                <div class="blog-list-item">
                    <img src="assets/img/blog4.jpg" class="img-fluid">
                    <h5>Lorem ipsum dolor </h5>
                    <span>08-11-2022</span>
                </div>
            </div>
            <div class="col-lg-4 mt-5">
                <div class="blog-list-item">
                    <img src="assets/img/blog5.jpg" class="img-fluid">
                    <h5>Lorem ipsum dolor </h5>
                    <span>08-11-2022</span>
                </div>
            </div>
            <div class="col-lg-4 mt-5">
                <div class="blog-list-item">
                    <img src="assets/img/blog1.jpg" class="img-fluid">
                    <h5>Lorem ipsum dolor </h5>
                    <span>08-11-2022</span>
                </div>
            </div>
        </div> -->

    </div>
</section>


<!-- <div class="listing-area mt-5">
    <div class="container">
        <ul class="listing">
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>></li>
        </ul>
    </div>
</div> -->

<div class="pagination-area">
    <div class="container">
        <pagination-controls class="my-pagination" (pageChange)="p = $event" class="float-right"></pagination-controls>
    </div>
</div>




<div class="bread-crumb mt-5">
    <div class="container">
        <ul class="breadcrumb">
            <li><a href="#">Home</a></li>

            <li>About Us</li>
        </ul>
    </div>
</div>



<section class="about-page mt-5">
    <div class="container">
        <img src="assets/img/about-page.jpg" class="img-fluid mobhide">
        <img src="assets/img/about-page1.jpg" class="img-fluid deskhide">

        <div class="about-intro mt-5 sub-title">
            <p>
                Dr. Hafi's Revive Skin Clinic isn't like any other dermatological clinic in Kozhikode. We are a full-fledged healthcare clinic dedicated to improving your skin's health and overall wellness. Whether you've been struggling with a chronic skin issue, seeking ways to alleviate signs of aging, or merely want to begin a healthy skincare regimen, our team of experts is here to guide you every step of the way.
            </p>
            <h2> Unparalleled Expertise: </h2>
            <p>Our zeal starts with a wide team of highly skilled dermatologists. They boast significant knowledge and expertise in treating a wide range of skin problems, including acne and eczema, in addition to more complex issues like psoriasis and infections. We stay ahead of medical progress, leveraging innovative technologies and proven treatment regimens to provide the most successful treatments for your unique needs.</p>
            <h2> Tailored Solutions for Every Concern: </h2>
            <p>Revive Skin Clinic believes in a customized approach. Before designing a treatment plan, we carefully analyze your condition, medical history, and expected outcomes. This meticulous approach applies to all of our services:</p>
            <p>
                General Dermatology: Our dermatologists can diagnose and successfully treat an array of skin disorders. We provide treatments for acne, eczema, psoriasis, rashes, infections, and more.
            </p>
            <p>
                Cosmetic Dermatology: Our non-surgical cosmetic procedures enable you to age gracefully while also enhancing your natural beauty. We provide a wide range of injectables, including Botox and fillers, laser treatments for sun damage and uneven skin tone, and chemical peels for rejuvenation.
            </p>
            <p>
                Hair Restoration: Hair loss may be an issue of concern for many. Our hair clinic offers efficient treatments for thinning hair and healthy hair growth. We provide customized treatment methods based on your unique needs, whether you're experiencing male or female pattern baldness.
            </p>
            <p>
                Psychodermatology: The mind and body connect to sustain skin health. We understand how anxiety and stress may present themselves in eczema and psoriasis. Our medical team provides specialized treatment that addresses both the physical and emotional aspects of these disorders, resulting in a complete recovery.
            </p>
            <p>
                Palliative Dermatology: If you suffer from a persistent illness, you might encounter skin conditions that require specific therapy. Our clinic offers compassionate and safe palliative dermatological treatment to relieve your symptoms and enhance your quality of life.
            </p>
            <p>
                STD Testing and Treatment: We acknowledge the significance of private prompt screening and treatment for sexually transmitted diseases (STDs). Our clinic provides a secure and friendly environment for STD diagnosis and management.
            </p>
            
            <h2> Your Journey to Healthy Skin Starts Here: </h2>
            <p>
                Revive Skin Clinic goes beyond just treating your skin. We believe in empowering you to take charge of your skin health. Our team is dedicated to providing:
            </p>
            <p>
                Personalized consultations: We take the time to understand your concerns, answer your questions, and discuss all available treatment options in detail.
            </p>
            <p>
                Clear explanations: We believe in clear communication. Our team explains diagnoses and treatment plans in a way that is easy to understand.
            </p>
            <p>
                Ongoing support: Your journey to healthy skin doesn't end after your appointment. We provide ongoing support and guidance to ensure you achieve and maintain your desired results.
            </p>
        </div>
    </div>
</section>


<section class="make-diffrent mt-50">
    <div class="container">
        <h2 class="text-center"> What Makes us Diffrent</h2>
        <div class="row ">
            <div class="col-lg-3 col-6 mt-5">
                <div class="diffrent-feature-details">
                    <div class="diffrent-feature">
                        <img src="assets/img/diffrent-ic1.png" class="diffrent-ic">

                    </div>
                    <h5>Friendly team</h5>
                </div>
            </div>
            <div class="col-lg-3 col-6 mt-5">
                <div class="diffrent-feature-details">
                    <div class="diffrent-feature">
                        <img src="assets/img/diffrent-ic2.png" class="diffrent-ic">
                    </div>
                    <h5>Proper communication</h5>
                </div>
            </div>
            <div class="col-lg-3 col-6 mt-5">
                <div class="diffrent-feature-details">
                    <div class="diffrent-feature">
                        <img src="assets/img/diffrent-ic3.png" class="diffrent-ic">
                    </div>
                    <h5>Ethical treatment</h5>
                </div>
            </div>
            <div class="col-lg-3 col-6 mt-5">
                <div class="diffrent-feature-details">
                    <div class="diffrent-feature">
                        <img src="assets/img/diffrent-ic4.png" class="diffrent-ic">
                    </div>
                    <h5>Evening clinics</h5>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="doctors mt-50 sub-title">
    <div class="container">
        <h2 class="text-center">Doctors</h2>
        <div class="row">
            <div class="col-lg-3 mt-5">
                <div class="doctors-overlay-area">
                    <div class="doctors-content doctors-overlay-item">
                        <img src="assets/img/dr_one.jpg" class="img-fluid">
                        <h5 class="pb-1">Dr. Bishurul Hafi NA</h5>
                        <span>MBBS, MD (DVD), DNB, FECSM</span>
                        <p>Dr. Bishurul Hafi NA is an expert dermatologist practicing at Revive Skin Clinic...</p>
                        <div class="more-button-area ">
                            <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button class="more-button">Know More &nbsp;<i class="fas fa-arrow-right"></i></button></a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 mt-5">
                <div class="doctors-overlay-area">
                    <div class="doctors-content doctors-overlay-item">
                        <img src="assets/img/doc2.jpg" class="img-fluid">
                        <h5 class="pb-1">Dr. Nikhila PK</h5>
                        <span>MBBS MD DNB MNAMS</span>
                        <p>Did MBBS and MD from Govt medical college Kozhikode. Has an experience of 7 years..</p>
                        <div class="more-button-area ">
                            <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button class="more-button">Know More &nbsp;<i class="fas fa-arrow-right"></i></button></a>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-3 mt-5">
                <div class="doctors-overlay-area">
                    <div class="doctors-content doctors-overlay-item">
                        <img src="assets/img/dr_two.jpg" class="img-fluid">
                        <h5>Dr. Ajina M</h5>
                        <span>MBBS, DDVL</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus ultrices vitae urna..</p>
                        <div class="more-button-area ">
                            <a routerLinkActive="active" [routerLink]="['/doctor-ajina-detail']"><button class="more-button">Know More &nbsp;<i class="fas fa-arrow-right"></i></button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="docters-mobile" class="docters-mobile">
    <h2 class="text-center">Doctors</h2>
    <div class="docters_slide  ">

        <div class="docters-slider">
            <owl-carousel-o [options]="doctersOptions">

                <ng-container>

                    <ng-template carouselSlide>
                        <div class="single-docters-card ">
                            <div class="doctors-overlay-area">
                                <div class="doctors-content doctors-overlay-item">
                                    <img src="assets/img/doc1.jpg" class="img-fluid">
                                    <h5 class="pb-1">Dr. Bishurul Hafi NA</h5>
                                    <span>MBBS, MD (DVD), DNB, FECSM</span>
                                    <p>Dr. Bishurul Hafi NA is an expert dermatologist practicing at Revive Skin Clinic...
                                    </p>
                                    <div class="more-button-area ">
                                        <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button
                                                class="more-button">Know More &nbsp;<i
                                                    class="fas fa-arrow-right"></i></button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-docters-card">
                            <div class="doctors-overlay-area">
                                <div class="doctors-content doctors-overlay-item">
                                    <img src="assets/img/dr_two.jpg" class="img-fluid">
                                    <h5>Dr. Ajina M</h5>
                                    <span>MBBS, DDVL</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus ultrices vitae urna..</p>
                                    <div class="more-button-area ">
                                        <a routerLinkActive="active" [routerLink]="['/doctor-ajina-detail']"><button
                                                class="more-button">Know More &nbsp;<i
                                                    class="fas fa-arrow-right"></i></button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                 


                </ng-container>


            </owl-carousel-o>

        </div>

    </div>


</section>
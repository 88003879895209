<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li>Facilities</li>
      </ul>
    </div>
</div>


<section class="facilities-list mt-5">
    <div class="container">
        <h2 class="text-center">Our Facilities</h2>
        <div class="row">
            <div class="col-lg-4  mt-5">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Consultation Room.jpg" class="img-fluid">
                        <h5 class="fac_title">Consultation Room</h5>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4  mt-5">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Laser Room.jpg" class="img-fluid">
                        <h5 class="fac_title">Laser Room</h5>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4  mt-5">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Pharmacy.jpg" class="img-fluid">
                        <h5 class="fac_title">Pharmacy</h5>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 mt-5">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Procedure Room 1.jpg" class="img-fluid">
                        <h5 class="fac_title">Procedure Room I</h5>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4  mt-5">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Procedure Room 2.jpg" class="img-fluid">
                        <h5 class="fac_title">Procedure Room II</h5>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4  mt-5">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Reception.jpg" class="img-fluid">
                        <h5 class="fac_title">Reception</h5>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4  mt-5">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Rejuvenation Room.jpg" class="img-fluid">
                        <h5 class="fac_title">Rejuvenation Room</h5>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-lg-4  mt-5">
            <div class="overlay-about">
              <div class="overlay-about">
                <div class="facilities-item">
                    <div class="content-section">
                        <img src="assets/img/Facilities/Waiting Room.jpg" class="img-fluid">
                        <h5 class="fac_title">Waiting Room</h5>
                    </div>
                </div>
            </div>
          </div>
        </div>
        </div>
    </div>
</section>









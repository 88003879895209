import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/provider/blog.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {

  blog_id:any;
  blogDetail:any=[]
  is_skelton:boolean=false
  blogs:any;

  constructor(public route:ActivatedRoute,public blog:BlogService) {
    this.route.params.subscribe(val => {
      this.blog_id = val['id']
      console.log(this.blog_id)
      // this.pageId='log-detail/'+this.blog_id
    })
   }

  ngOnInit(): void {
    this.getBlogView();
    this.getBlogs()
  }

  changeData(id:any){
    console.log(id)
    this.blog_id=id
    this.blogDetail=[]
    this.getBlogView()
    this.getBlogs()
    window.scroll(0,0)
  }

  getBlogs(){
    this.is_skelton=true
    this.blog.getblogList().subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        console.log(resp)
        this.blogs = resp.data.data
      
      }
    })
  }

  getBlogView(){
    this.blog.getBlogView(this.blog_id).subscribe(resp=>{
      if(resp.success){
       this.blogDetail=resp.data
      }
    })
  }
}
<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li>Publications</li>
      </ul>
    </div>
</div>


<section class="publications-list sub-title mt-5">
    <div class="container">
        <h2 class="text-center">Publications</h2>
        <div class="row">
            <div class="col-lg-12 mt-5">
                <a href="https://pubmed.ncbi.nlm.nih.gov/33217228/
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Uvais NA, Latheef EN, Hafi B, et al. Depression Literacy Among Dermatologists: An Online Survey Study in India. The Primary Care Companion to The Journal of Clinical Psychiatry 2020.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="http://www.odermatol.com/odermatology/2020e/E194.Dermatologically-HafiB.pdf
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Hafi B, Singh N, Devi B, Nongalleima K, Mutum S, Bachaspatimayum R. Dermatologically relevant biomedicinal plants in Manipur, India. Our Dermatol Online. 2020.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            
        </div>

        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="http://www.odermatol.com/odermatology/20184/13.Zosetriform-BachaspatimayumR.pdf 
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Bachaspatimayum R, Hafi B, Duraswamy P, Bipin Th. Zosteriform cutaneous squamous cell metastasis from carcinoma cervix- a rare case report. Our Dermatol Online 2018 .</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.ijord.com/index.php/ijord/article/view/262
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Kshetrimayum S, Thokchom NS, Puii V, Hafi NB. Lichen sclerosus- a clinical study. Int J Res Dermatol 2017.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            
        </div>

        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.ijord.com/index.php/ijord/article/view/261
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Kshetrimayum S, Thokchom NS, Puii V, Hafi NB.Pattern of geriatric dermatoses at a tertiary care centre in Northeast India. Int J Res Dermatol 2017.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.ijord.com/index.php/ijord/article/view/236
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Sailo L, Devi TB, Singh TB, Hafi BN*. Dermatological manifestations of acquired immunodeficiency syndrome- an observational study among HAART naive patients from an ART centre of excellence. Int J Res Dermatol 2017.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            
        </div>

        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.journalijar.com/article/8635/imatinib-causing-drug-rash-with-eosinophilia-and-systemic-symptoms-a-case-report/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>Paswett F, Hafi BN, Bachaspatimayum R, Devi TB. Imatinib causing drug rash with eosinophilia and systemic symptoms- a case report. International Journal of Advanced Research 2016.</p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://dergipark.org.tr/en/download/article-file/920607
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Hafi B, Uvais NA Psoriasis and Schizophrenia An Interesting Association. Ulutas Med J. 2019.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.indianjrheumatol.com/article.asp?issn=0973-3698;year=2021;volume=16;issue=2;spage=194;epage=199;aulast=Afra
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Afra T P, Uvais N A, Anuradha A, Edavalath S, Salin E, Hafi BNA, Muhammed RT. Knowledge on hydroxychloroquine prescription and attitude toward its use in COVID-19 – A survey-based study among doctors. Indian Journal of Rheumatology.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="http://www.odermatol.com/odermatology/2020S2/9.COVID-19-India2.pdf
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Hafi B, Uvais NA, Razmi MT, Afra TP, Silah KS. The unfolding public health crisis of transitional homelessness during the COVID-19 pandemic in India: A psychodermatologic perspective. Our Dermatol Online. 2020.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.who.int/publications/i/item/9789240003927
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Uvais NA, Shihabudheen P, Bishurul Hafi NA. Perceived stress and stigma among doctors working in COVID-19–designated hospitals in India. Prim Care Companion CNS Disord. 2020.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://onlinelibrary.wiley.com/doi/10.1111/dth.14024
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>Hafi B, Abdul Latheef EN, Uvais N, et al. Awareness of psychodermatology in Indian dermatologists: A South Indian perspective. Dermatologic Therapy. 2020.</p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://psycnet.apa.org/record/2020-57516-008
                " target="_blank">
                    <div class="publications-details overlay-area">
                        <p>Hafi B, Uvais NA. Difficulties faced by sexual and gender minorities during COVID-19 crisis. Psychiatry and clinical neuroscience 2020.</p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/32618069/
                ">
                <div class="publications-details overlay-area">
                    <p>
                    Hafi B, Uvais NA, Afra TP, Razmi MT, Jafferany M, Latheef ENA. Hair transplantation, body dysmorphic disorder, and patients' attitude: A survey-based study from south India. Dermatology Therapy .
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>

        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7300678/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                    Hafi B, Uvais NA, Jafferany M, Afra TP, Razmi MT. Impact of COVID-19 pandemic on patients with obsessive compulsive and related disorders—Psychodermatological perspectives. Dermatology Therapy 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7744916/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Hafi B, Uvais NA, Jafferany M, Afra TP, Razmi MT. Palliative psychodermatology care during COVID-19 pandemic. Dermatologic Therapy. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/dth.13679
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Hafi B, Uvais NA, Jafferany M, Afra TP, Razmi MT. Can COVID-19 virus be transmitted through sex? Dermatologic Therapy. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.researchgate.net/publication/346055612_Management_of_a_case_of_Pemphigus_Foliaceus_in_a_Paraplegic_man_with_Palliative_Dermatology_principles_during_COVID-19_pandemic
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Hafi B, Uvais NA, Jafferany M, Afra TP, Razmi MT. “Masked” empathy—A post-pandemic reality: Psychodermatological perspective. Dermatologic Therapy. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>



        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/31643110/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Afra TP, Vinay K, Razmi MT, Khader A, Hafi BNA. Novel dermoscopic features of pityriasis versicolor–like macules in epidermodysplasia verruciformis. Pediatric Dermatology. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7340024/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Afra TP, Nampoothiri R, Razmi MT, Hafi BNA. Linking hydroxychloroquine to hemolysis in a ‘suspected’ glucose-6-phosphate dehydrogenase deficient patient with COVID-19 infection – a critical appraisal. European Journal of Internal Medicine 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>



        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7832625/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Afra TP, Nampoothiri R, Razmi MT, Hafi BNA. Linking hydroxychloroquine to hemolysis in a ‘suspected’ glucose-6-phosphate dehydrogenase deficient patient with COVID-19 infection – a critical appraisal. European Journal of Internal Medicine 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://gpsych.bmj.com/content/33/3/e100181
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Uvais NA, Rakhesh SV, Afra TP, et al. Comorbid psoriasis- bipolar disorder successfully treated with apremilast: much more than a mere coincidence? General Psychiatry 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>



        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="http://instadr.in/v2/doctor/dr.bishurul/about.html
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Afra TP, Hafi BNA, Razmi MT. Dressing gauzes anchored to elastic tape using silk sutures (“hammock dressing”) for a sturdy head bandage. Journal of American Academy of Dermatology. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/25965180/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Afra TP, Razmi MT, Hafi BNA. Mortise and tenon type elastic adhesive bandage for non-invasive closure of oozy wounds at areas of tension. . Journal of American Academy of Dermatology. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/books/NBK537131/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Afra TP, Hafi BNA, Razmi MT. Smear the footplate with soap water to prevent misting up during dermoscopy. Journal of American Academy of Dermatology. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.psychiatrist.com/pcc/depression/sexual-dysfunction/delayed-ejaculation-man-premature-orgasm-woman/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Hafi B, Uvais NA, Rabeeh V, et al. Delayed ejaculation in a man and premature orgasm in a woman: 2 cases with a common suspect. Prim Care Companion CNS Disord. 2021.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>



        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="http://www.odermatol.com/odermatology/2020e/E230.Venereophobia-HafiNAB.pdf
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Hafi B, Uvais NA, Latheef EN, Razmi MT, Afra TP, Aysha S. Venereophobia – a cognitive deception? Case reports with literature review. Our Dermatol Online. 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.who.int/news-room/questions-and-answers/item/coronavirus-disease-(covid-19)-post-covid-19-condition
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            NA Uvais, Moideen S, Hafi B, et al. Insomnia in COVID-19 Survivors: A Cross-Sectional Study Among Healthcare Workers. Chronobiology in Medicine 2021.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
            </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.chronobiologyinmedicine.org/m/journal/view.php?number=88
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            NA Uvais, Moideen S, Hafi B, et al. Insomnia among Active Patients with COVID-19: A Cross-Sectional Study. Chronobiology in Medicine 2021.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://iqraahospital.in/doctor/uvais/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Hafi, B., Uvais, N.A.,Rabeeh, V., Arjun, P.C., Tonjam, R., Arafat, S.M.Y.(2020). Effect of COVID-19 lockdown on sexualminorities: A pilot survey study in India. Indian Journal of Health, Sexuality & Culture, 6(2), 60-65.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>




        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7744916/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Hafi B, Uvais NA, Jafferany M, et al. Management of a case of pemphigus foliaceus in a paraplegic man with palliative dermatology principles during COVID-19 pandemic. Dermatologic Therapy 2021.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/29644197/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Thokchom NS, Pradeepa D, Hafi NB*, Verma K. Clinical spectrum of autoerythrocyte sensitization syndrome: A series of five cases. Indian Dermatol Online J 2018.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="http://instadr.in/v2/doctor/dr.bishurul/about.html
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Afra TP, Razmi TM, Bishurul Hafi NA. IgG4-related skin diseases: A brief review. J Skin Sex Transm Dis 2020;2(2):94-8.Nagarajan K, Thokchom NS, Ibochouba K, Verma K, Hafi NB*. Pattern of pediatric dermatoses in Northeast India. Indian J Paediatr Dermatol 2017.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
            </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6232986/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Dabett, Devi TB, Hafi B*. Skin changes during pregnancy- a study from Northeast India. Indian Dermatol Online J 2018.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6069624/
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Afra TP, Razmi TM, Bishurul Hafi NA. IgG4-related skin diseases: A brief review. J Skin Sex Transm Dis 2020;2(2):94-8.Nagarajan K, Thokchom NS, Ibochouba K, Verma K, Hafi NB*. Pattern of pediatric dermatoses in Northeast India. Indian J Paediatr Dermatol 2017.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.jmedsoc.org/article.asp?issn=0972-4958;year=2018;volume=32;issue=2;spage=154;epage=156;aulast=Thokchom
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Dabett, Devi TB, Hafi B*. Skin changes during pregnancy- a study from Northeast India. Indian Dermatol Online J 2018.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
            </a>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.worldwidejournals.com/paripex/recent_issues_pdf/2018/October/October_2018_1539440189__105.pdf
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Hafi B, Uvais NA. Palliative Dermatology. Indian J Palliat Care 2018.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
            </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.researchgate.net/publication/332170781_Childhood_vitiligo_A_hospital-based_study_on_200_patients_in_Northeast_India
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Thokchom NS, Verma K, Sangma KA, Bishurul HN*. Hyper‑IgE syndrome. J Med Soc32(2);154-6.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
            </a>
            </div>
            
        </div>

        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://www.ijph.in/article.asp?issn=0019-557X;year=2020;volume=64;issue=6;spage=249;epage=250;aulast=Uvais
                " target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                        Thokchom NS, Verma K, Sangma KA, Bishurul HN*. Hyper‑IgE syndrome. J Med Soc32(2);154-6.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
            </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://www.worldwidejournals.com/paripex/recent_issues_pdf/2018/October/October_2018_1539440189__105.pdf" target="_blank">
                    <div class="publications-details overlay-area">
                        <p>
                        Hafeeq B, Hafi NAB, Mohammed TP, Uvais NA. Sleep quality among doctors working in private sector. Paripex- Indian Journal of Research 2018.
                        </p>
                        <div class="overlay">
                            <div class="overlay-text">Know More</div>
                        </div>
                    </div>
                </a>
            </div>
            
        </div>



        <div class="row">
            <div class="col-lg-12 mt-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/16008644/" target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Bishurul Hafi NA, Thokchom NS, Singh SC, Bachaspatimayum R. Childhood vitiligo: A hospital-based study on 200 patients in Northeast India. Indian J Paediatr Dermatol 2019.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            <div class="col-lg-12 mt-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/32496269/" target="_blank">
                <div class="publications-details overlay-area">
                    <p>
                            Uvais NA, Nalakath MJ, Shihabudheen P, Hafi NB, Rasmina V, Salman CA. Psychological distress during COVID-19 among malayalam-speaking indian experts in the middle east. Indian J Public Health 2020.
                    </p>
                    <div class="overlay">
                        <div class="overlay-text">Know More</div>
                    </div>
                </div>
                </a>
            </div>
            
        </div>

        

    </div>
</section>

<!-- <div class="listing-area mt-5">
    <div class="container">
        <ul class="listing">
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>></li>
        </ul>
    </div>
</div> -->
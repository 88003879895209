import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/provider/home.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogList:any=[]
  p: number = 1;

  constructor( public home:HomeService) { }

  ngOnInit(): void {
    this.getblogList()
   
  }
  
  getblogList(){
    this.home.getblogList().subscribe(resp=>{
      if(resp.success=true){
        this.blogList=resp.data.data
      }
    })
  }



}

<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li>Contact</li>
        
      </ul>
    </div>
</div>

<section class="contact-area mt-5">
    <div class="container">
        <h2 class="text-center">Location</h2>
        <div class="row justify-content-center">
            <div class="col-lg-12 mt-5">
                <div class="location-details d-flex">
                    <img src="assets/img/location-ic.png" height="22px;" width="20px;">
                    <p>Kannakandi Mansion, Police station, near Nadakkav, Kozhikode, Kerala 673006</p>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-3 mt-2">
                <div class="location-details d-flex">
                    <img src="assets/img/location-ic2.png" height="22px;" width="20px;">
                    <p class="tel_feild"> <a href="tel:+919995606163">+91 9995606163</a></p>
                </div>
            </div>
            <div class="col-lg-3 mt-2">
                <div class="location-details d-flex">
                    <img src="assets/img/location-ic3.png" height="22px;" width="20px;">
                    <p class="email_feild"> <a href="mailto:bishuru@gmail.com">bishuru@gmail.com</a></p>
                </div>
            </div>
        </div>
        <div class="gl-map mt-5">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15651.405869815377!2d75.77513!3d11.2723269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65be1207d3887%3A0x4b3f22a0bcee83b8!2sDr.%20Hafi&#39;s%20Revive%20Skin%20Clinic!5e0!3m2!1sen!2sin!4v1678171423093!5m2!1sen!2sin"
                width="100%" height="430px" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            
        </div>
    </div>
</section>




<div class="container mt-5">
            <div class="form-item">
                <form autocomplete="off" [formGroup]="contactForm">
                    <div class="row form-field-area">
                
                        <div class="col-lg-6 col-md-12 mt-4">
                           
                            <div class="form-field">
                               <label>NAME</label>
                                <input class="form-min mt-2" name="first_name" placeholder="Enter your name*" formControlName="name" />
                                <div *ngIf="submitted && acf.name.errors" class="invalid">
                                    <div class="form-error-msg" *ngIf="acf.name.errors.required">*Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="col-lg-6 col-md-12 mt-4">
                            <div class="form-field">
                                <label>EMAIL ADDRESS</label>
                                <input class="form-min mt-2" name="email" placeholder="Your email address*" formControlName="email" />
                                <div *ngIf="submitted && acf.email.errors" class="invalid">
                                    <div class="form-error-msg" *ngIf="acf.email.errors.required">*Email is required
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </div>
                    <div class="row form-field-area ">
        
                        <div class="col-lg-6 col-md-12 mt-4">
                            <div class="form-field">
                                <label>PHONE</label>
                                <input class="form-min mt-2" name="mobile_number" placeholder="Enter your Phone Number*" formControlName="phone" />
                                <div *ngIf="submitted && acf.phone.errors" class="invalid">
                                    <div class="form-error-msg" *ngIf="acf.phone.errors.required">*Phone is required
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="col-lg-6 mt-4">
                            <div class="form-field">
                                <label>SERVICES</label>
                            <select id="cars" name="carlist" form="carform" class="select-box mt-2" formControlName="service">
                                <option value="1">General Dermatology</option>
                                <option value="2">Cosmetic Treatments</option>
                                <option value="3">Hair Clinic</option>
                                <option value="4">Psychodermatology & Palliative Dermatology</option>
                                <option value="5">STD (Sexually Transmitted Disease)</option>
                                <option value="6">Amore Centre For Sexual Medicine and Relationship Therapy</option>
                                
                              </select>
                            </div>
                        </div>
        
                    </div>
                    <div class="row form-field-area">
                        <div class="col-lg-12 col-md-12 mt-4">
                            <div class="contact-text-area">
                                <label>MESSAGE</label>
                                <textarea class="form-text-area mt-2" formControlName="message" placeholder="Enter your messages*" rows="4"></textarea>
                                <div *ngIf="submitted && acf.message.errors" class="invalid">
                                    <div class="form-error-msg" *ngIf="acf.message.errors.required">*Message is required
                                    </div>
                                </div>
                                <div class="button-item mt-3">
                                    <button type="submit" class="contact-button border-gradient-green" (click)="submitForm()">Send Message</button>
                                    <span class="contact-message"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            
            </div>
      

    </div>
import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(public api:Api) { }

  getVideoGallery(){
    let endpoint='gallery'
    
    return this
    .api
    .get(endpoint)    
  }

}

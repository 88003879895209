<body>

    <div class="bread-crumb mt-5">
        <div class="container">
        <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <li><a [routerLink]="['/blogs']">Blog</a></li>
            <li>Blog Details</li>
          </ul>
        </div>
    </div>
    


    <section id="Journals" style="display: block;">
        <div class="container mx-auto">
          <div class="page-title">
              <h1></h1>
              <p></p>  
            </div>
          <div class="row">
            <div class="col-md-8 col-sm-12 main-Journals blog-details">
              <img src="{{blogDetail.image_url}}" alt="">
              <h2>{{blogDetail.title}}</h2>
              <span>{{blogDetail.created_at | date :'dd MMM yyyy'}}</span>
                <p [innerHTML]="(blogDetail.description) ">
                   
                </p>
          
            </div>
  
            <div class="col-md-4 col-sm-12 more-Journals " *ngIf="blogs && blogs.length > 0">
              <h5 style="color: #20618E;">Related Blogs</h5>
              <div class="Journals-item d-flex mb-4 related-blog" *ngFor="let blog of blogs ; index as i" (click)="changeData(blog.id)">
                <ng-container *ngIf="blog.id !=blog_id">
                    <img src="{{blog.image_url}}" alt="">

                    <div class="">
                <a ><h5 class="blog_title">{{blog.title}}</h5></a>
                <p >{{blog.created_at | date :'dd MMM yyyy'}}</p>
              </div>
                </ng-container>
                
              </div>
              
            </div>
          </div>
        </div>
      </section>



    
     


    <a href="#" class="scrollToTop"><i class="fad fa-arrow-alt-to-top"></i></a>
    
    <!-- scrollToTop -->
    <script src="./scrollToTop.js"></script>

    <script src="./toggle.js"></script>

    <!-- AOS JS -->
    <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
    <script>
      AOS.init();
    </script>
    <!-- Bootstrap5 JS -->
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
    
    <!-- Swiper JS -->
    <script src="https://unpkg.com/swiper@8/swiper-bundle.min.js"></script>
    
    <!-- OWL JS -->
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script>
   

  </body>


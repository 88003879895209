
<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
      
        <li>Services</li>
      </ul>
    </div>
</div>

<section class="services-list-page ">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mt-4 order-lg-1 order-1">
                <div class="service-image">
                    <img src="assets/img/General Dermatology.jpg" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 mt-4 order-lg-2 order-2">
                <div class="service-listing-details">
                    <h2>General Dermatology</h2>
                    <p>With the help of advanced diagnostic tools, our outstanding certified dermatologists are popular for accurate clinical diagnosis and appropriate management. Our signature nutritional therapy for various skin disorders are well appreciated all over. Our expertise extends beyond the following list:	
                    </p>
                    <div class="more-button-area mt-2">
                        <a routerLinkActive="active" [routerLink]="['/dermatology']"><button class="more-button">Learn More &nbsp;<i class="fas fa-arrow-right more-arrow"></i></button></a>
                        </div>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            
            <div class="col-lg-6 mt-4 order-lg-1 order-2">
                <div class="service-listing-details">
                    <h2>Cosmetic Treatments</h2>
                    <p>Skin and face - We expertise ourselves in providing aesthetic and cosmetic treatments like Chemical peeling, Botox, Filler, Non surgical face lift, PRP/Vampire facial, Medi facial, Skin lightening treatments, Stretch marks, Acne and Acne scar, Keloid and hypertrophic scar, Injection lipolysis, Eye wrinkles and dark circles, (Wart, milia, skin tag, DPN)- removal, Mole removal, Ear lobe correction, Sebaceous cyst excision, Vitiligo surgery, Mesotherapy face.
                    </p>
                    <div class="more-button-area mt-2">
                            <a routerLinkActive="active" [routerLink]="['/cosmetic-treatment']"><button class="more-button">Learn More &nbsp;<i class="fas fa-arrow-right more-arrow"></i></button></a>
                        </div>
                </div>
            </div>
            <div class="col-lg-6 mt-4 order-lg-2 order-1">
                <div class="service-image">
                    <img src="assets/img/Cosmetic Treatments.jpg" class="img-fluid">
                </div>
            </div>
        </div>


        <div class="row mt-5">
            <div class="col-lg-6 mt-4 order-lg-1 order-1">
                <div class="service-image">
                    <img src="assets/img/Hair Clinic.jpg" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 mt-4 order-lg-2 order-2">
                <div class="service-listing-details">
                    <h2>Hair Clinic</h2>
                    <p>
                        Worried about hair loss? Our expert team is well equipped with most updated knowledge and advanced treatment techniques. Our mantra is to personalize the treatment protocol after proper analysis and investigation. Our special preparation of Platelet Rich Plasma (PRP) and Growth Factor Concentrate (GFC) has given hope to hundreds of clients anxious about the hair loss. We also have a highly experienced team of hair transplant experts offering perfect hair line with maximum density.
                    </p>
                    <div class="more-button-area mt-2">
                        <a routerLinkActive="active" [routerLink]="['/hair-clinic']"><button class="more-button">Learn More &nbsp;<i class="fas fa-arrow-right more-arrow"></i></button></a>
                        </div>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            
            <div class="col-lg-6 mt-4 order-lg-1 order-2">
                <div class="service-listing-details">
                    <h2> Psychodermatology & Palliative Dermatology</h2>
                    <p>
                        Mind has immense role in skin functions. Emotions can cause or exacerbate skin disorders. There are a handful of skin disorders which require detailed psychological evaluation and even psychiatric intervention. For that purpose we will have weekly liaison clinic with trained dermatologists and psychiatrists, first of a kind in a private clinic. Our team head Dr Bishurul Hafi is currently serving as the honourable national secretary of Psychodermatology Association of India (2021-23).
                    </p>
                    <!-- <p class="mt-3"><strong>Palliative dermatology clinic-</strong> As our philanthropic vision, our team introduced the concept of palliative dermatology to the world. We are collaborating with palliative organizations in India and abroad for advising the management of skin related problems in palliative patients, which is provided absolutely free. Our work had been admired widely and recognized by international academic institutions. You can read more in following journals.
                    </p> -->
                    <div class="more-button-area mt-2">
                            <a routerLinkActive="active" [routerLink]="['/psychodermatology']"><button class="more-button">Learn More &nbsp;<i class="fas fa-arrow-right more-arrow"></i></button></a>
                        </div>
                </div>
            </div>
            <div class="col-lg-6 mt-4 order-lg-2 order-1">
                <div class="service-image">
                    <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY 1.jpg" class="img-fluid">
                </div>
            </div>
        </div>



        <div class="row mt-5">
            <div class="col-lg-6 mt-4 order-lg-1 order-1">
                <div class="service-image">
                    <img src="assets/img/STD (Sexually Transmitted Disease).jpg" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 mt-4 order-lg-2 order-2">
                <div class="service-listing-details">
                    <h2>STD (Sexually Transmitted Disease)</h2>
                    <p>
                        We are the top STD centre in the region offering absolute confidentiality Screening for Sexually transmitted infections, Counselling for venereophobia, Treatment for Genital warts, Herpes genitalis, Balanoposthitis, Gonorrhea, Chlamydia, Syphilis, Bacterial and fungal infections for men and women.  
                    </p>
                    <p class="mt-2">Sexology clinic- Amore Centre for Sexual Medicine- Best sexual health clinic in Kerala for everyone.</p>
                    <div class="more-button-area mt-2">
                        <a routerLinkActive="active" [routerLink]="['/sexually-disease']"><button class="more-button">Learn More &nbsp;<i class="fas fa-arrow-right more-arrow"></i></button></a>
                        </div>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            
            <div class="col-lg-6 mt-4 order-lg-1 order-2">
                <div class="service-listing-details">
                    <h2>Amore Centre For Sexual Medicine and Relationship Therapy</h2>
                    <p>This unique center, one of the pioneers in the region, offers the most advanced treatment for all sexual concerns for men and women. We have specialized team for relationship therapy too. Our 360 degree approach towards sexual concerns is applauded widely which put us in number one option for sex solutions.</p>
                    <div class="more-button-area mt-2">
                            <a routerLinkActive="active" [routerLink]="['/amore-centre']"><button class="more-button">Learn More &nbsp;<i class="fas fa-arrow-right more-arrow"></i></button></a>
                        </div>
                </div>
            </div>
            <div class="col-lg-6 mt-4 order-lg-2 order-1">
                <div class="service-image">
                    <img src="assets/img/Amore Centre For Sexual Medicine and Relationship Therapy.jpg" class="img-fluid">
                </div>
            </div>
        </div>


       


    </div>
</section>
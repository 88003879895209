import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './provider/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'REVIVE';
  constructor(private router:Router,public seoService:SeoService,private activatedRoute: ActivatedRoute){


    this.router.events.pipe(
      filter((e:any) => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route:any) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route:any) => route.outlet === 'primary'),
      mergeMap((route:any) => route.data),
    ).subscribe((data:any) => {
      let seoData = data['seo'];
      console.log(seoData)
      if(seoData && seoData['title']){
      this.seoService.updateTitle(seoData['title']);
      }
      if(seoData && seoData['metaTags']){
      this.seoService.updateMetaTags(seoData['metaTags']);
      }
     
    });

  }

  ngOnInit(): void {

  }
  
}

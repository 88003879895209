import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GalleryService } from 'src/app/provider/gallery.service';

@Component({
  selector: 'app-video-gallery-list',
  templateUrl: './video-gallery-list.component.html',
  styleUrls: ['./video-gallery-list.component.scss']
})
export class VideoGalleryListComponent implements OnInit {

  galleryList:any=[]
  p: number = 1;

  constructor(public gallery:GalleryService,protected sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.getVideoGallery()
  }

  getVideoGallery(){

    this.gallery.getVideoGallery().subscribe((resp:any) => {
      if (resp.success == true) {
        
        this.galleryList =resp.data
        this.galleryList.forEach((element:any) => {
          element.convertedURL=this.getEmbedUrl(element.image);

        });
      }
    })
  }

  getEmbedUrl(url:string) {
    const regExp =  /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);

    const videoId= (match && match[1])  ? match[1]  : null;
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'  + videoId );
  }

}

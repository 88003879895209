import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sexually-disease',
  templateUrl: './sexually-disease.component.html',
  styleUrls: ['./sexually-disease.component.scss']
})
export class SexuallyDiseaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li><a routerLinkActive="active" [routerLink]="['/service-list']">Services</a></li>
        <li>Psychodermatology & Palliative Dermatology</li>
      </ul>
    </div>
</div>




<section class="service-details-area sub-title">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-8 mt-5">
                <div class="service-details-content">
                    <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY (1).jpg" class="img-fluid">
                    <h2>Psychodermatology</h2>
                    <p>
                        Mind has immense role in skin functions. Emotions can cause or exacerbate skin disorders. There are a handful of skin disorders which require detailed psychological evaluation and even psychiatric intervention. For that purpose we will have weekly liaison clinic with trained dermatologists and psychiatrists, first of a kind in a private clinic. Our team head Dr Bishurul Hafi is currently serving as the honourable national secretary of Psychodermatology Association of India (2021-23).    
                    </p>
                   
                    <!-- <hr> -->


                    <div class="paliative mt-5">
                        <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY 1.jpg" class="img-fluid">
                    </div>

                    <h2>Palliative Dermatology Clinic</h2>
                    <p>
                        As our philanthropic vision, our team introduced the concept of palliative dermatology to the world. We are collaborating with palliative organizations in India and abroad for advising the management of skin related problems in palliative patients, which is provided absolutely free. Our work had been admired widely and recognized by international academic institutions. You can read more in following journals.    
                    </p>

                </div>
            </div>
           


         
            



            <div class="col-lg-4 mt-5">
                <h5 style="color: #20618E; font-size: 20px;">Other Services</h5>
                
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/dermatology']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/ACNE.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>General Dermatology</h5>
                           
                        </div>
    
                    </div>
                </a>
             
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/cosmetic-treatment']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/Skin tag - Wart- DPN Removal.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Cosmetic Treatments</h5>

                        </div>


                    </div>
                </a>
              
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/hair-clinic']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/Hair Transplant.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Hair Clinic</h5>

                        </div>


                    </div>
                </a>


                <a class="service_tag" routerLinkActive="active" [routerLink]="['/sexually-disease']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/5.-What is an unconsummated marriage.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>STD (Sexually Transmitted Disease)</h5>

                        </div>


                    </div>
                </a>
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/amore-centre']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/amore.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Amore Centre For Sexual Medicine And Relationship Therapy</h5>

                        </div>


                    </div>
                </a>


            </div>

        </div>
    </div>
</section>



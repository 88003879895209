<body>
    <div class="mobhide">
        <section id="banner-areag" class="banner-area-main">

            <div class="container mt-5">

                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-interval="3000">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                            class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>

                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>

                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">

                                <div class="col-lg-8">
                                    <div class="main-content center-align-text">
                                        <h1>RESTORE. REJUVENATE. REVITALIZE
                                        </h1>
                                        <p class="mt-3">
                                        </p>

                                    </div>
                                    <div class="banner-button-area">
                                        <button class="banner-btn1 mt-4">{{myDate | date:'EEEE'}} 10:30 am - 05:30
                                            pm</button>
                                        <button type="button " class="btn banner-btn2  plan-button-item"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Plan Your Visit &nbsp; <i class="fas fa-arrow-right"></i>

                                        </button>
                                    </div>
                                </div>

                                <div class="col-lg-4 mb-5">
                                    <div class="first-image">
                                        <img src="assets/img/banner2.png" class="img-fluid">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="carousel-item ">
                            <div class="row">

                                <div class="col-lg-8">
                                    <div class="main-content center-align-text">
                                        <h1>Quality Service at affordable pricing
                                        </h1>
                                        <p class="mt-3">
                                        </p>

                                    </div>
                                    <div class="banner-button-area">
                                        <button class="banner-btn1 mt-4">{{myDate | date:'EEEE'}} 10:30 am - 05:30
                                            pm</button>

                                        <button type="button " class="btn banner-btn2  plan-button-item"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Plan Your Visit &nbsp; <i class="fas fa-arrow-right"></i>

                                        </button>
                                    </div>

                                </div>

                                <div class="col-lg-4">
                                    <div class="first-image">
                                        <img src="assets/img/banner1.png" class="img-fluid">
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div class="carousel-item ">
                            <div class="row">

                                <div class="col-lg-8">
                                    <div class="main-content center-align-text">
                                        <h1>IT’S A CLINIC, IT’S A CONCEPT
                                        </h1>
                                        <p class="mt-3">
                                        </p>

                                    </div>
                                    <div class="banner-button-area">
                                        <button class="banner-btn1 mt-4">{{myDate | date:'EEEE'}} 10:30 am - 05:30
                                            pm</button>

                                        <button type="button " class="btn banner-btn2  plan-button-item"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Plan Your Visit &nbsp; <i class="fas fa-arrow-right"></i>

                                        </button>
                                    </div>

                                </div>

                                <div class="col-lg-4">
                                    <div class="first-image">
                                        <img src="assets/img/banner3.png" class="img-fluid">
                                    </div>
                                </div>

                            </div>

                        </div>



                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

        </section>
    </div>

    <div class="deskhide">
        <section id="banner-areag" class="banner-area-main">

            <div class="container mt-5">

                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-interval="3000">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                            class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>

                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>

                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">

                                <div class="col-lg-5">
                                    <div class="first-image">
                                        <img src="assets/img/banner1.png" class="img-fluid">
                                    </div>
                                </div>

                                <div class="col-lg-7 mt-5">
                                    <div class="main-content center-align-text">
                                        <h1>RESTORE. REJUVENATE. REVITALIZE
                                        </h1>
                                        <p class="mt-3">
                                        </p>

                                    </div>
                                    <div class="banner-button-area">
                                        <button class="banner-btn1 mt-4">{{myDate | date:'EEEE'}} 10:30 am - 05:30
                                            pm</button>
                                        <button type="button " class="btn banner-btn2  plan-button-item mt-4"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Plan Your Visit &nbsp; <i class="fas fa-arrow-right"></i>

                                        </button>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div class="carousel-item ">
                            <div class="row">

                                <div class="col-lg-5">
                                    <div class="first-image">
                                        <img src="assets/img/banner2.png" class="img-fluid">
                                    </div>
                                </div>

                                <div class="col-lg-7  mt-5">
                                    <div class="main-content center-align-text">
                                        <h1>Quality Service at affordable pricing
                                        </h1>
                                        <p class="mt-3">
                                        </p>

                                    </div>
                                    <div class="banner-button-area">
                                        <button class="banner-btn1 mt-4">{{myDate | date:'EEEE'}} 10:30 am - 05:30
                                            pm</button>
                                        <button type="button " class="btn banner-btn2  plan-button-item mt-4"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Plan Your Visit &nbsp; <i class="fas fa-arrow-right"></i>

                                        </button>
                                    </div>
                                </div>



                            </div>

                        </div>

                        <div class="carousel-item ">
                            <div class="row">

                                <div class="col-lg-5">
                                    <div class="first-image">
                                        <img src="assets/img/banner3.png" class="img-fluid">
                                    </div>
                                </div>

                                <div class="col-lg-7  mt-5">
                                    <div class="main-content center-align-text">
                                        <h1>IT’S A CLINIC, IT’S A CONCEPT
                                        </h1>
                                        <p class="mt-3">
                                        </p>

                                    </div>
                                    <div class="banner-button-area">
                                        <button class="banner-btn1 mt-4">{{myDate | date:'EEEE'}} 10:30 am - 05:30
                                            pm</button>
                                        <button type="button " class="btn banner-btn2  plan-button-item mt-4"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Plan Your Visit &nbsp; <i class="fas fa-arrow-right"></i>

                                        </button>
                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

        </section>
    </div>



    <!-- <section id="banner-areag" class="banner-area-main">
        <div class="container mt-5">

            <div class="row">

                <div class="col-lg-7">
                    <div class="main-content center-align-text">
                        <h1>RESTORE. REJUVENATE. REVITALIZE
                        </h1>
                        <p class="mt-3">
                        </p>
                        
                    </div>
                    <div class="banner-button-area">
                        <button class="banner-btn1 mt-4">Thursday 08:00 am - 06:00 pm</button>
                        <button class="banner-btn2 mt-4">Plan Your Visit &nbsp;&nbsp;<img src="assets/img/arrow-light2.png"></button>
                    </div>
                </div>

                <div class="col-lg-5 mt-5">
                    <div class="first-image">
                        <img src="assets/img/banner1.png" class="img-fluid">
                    </div>
                </div>

            </div>

        </div>
    </section> -->








    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">PLAN YOUR VISIT</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body pb-5 pt-5">

                    <div class="row">
                        <div class="timings d-flex">
                            <div class="timing-data">
                                <h6>Monday - Saturday </h6>
                            </div>
                            <div class="timing-data">
                                <h6>10:30 am - 05:30 pm</h6>
                            </div>



                        </div>

                        <div class="timings d-flex mt-4">
                            <div class="timing-data">
                                <h6>Sunday </h6>
                            </div>
                            <div class="timing-data">
                                <h6>Closed</h6>
                            </div>
                        </div>


                    </div>

                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                </div> -->
            </div>
        </div>
    </div>


    <section class="services-area mt-50">
        <div class="container">
            <h2 class="text-center">Services</h2>
            <div class="row">
                <div class="col-lg-3 mt-5">
                    <a routerLinkActive="active" [routerLink]="['/dermatology']" class="service-a">
                        <div class="service-overlay-area">
                            <div class="services-item service-overlay-item">

                                <img src="assets/img/services-ic1.png" width="50px;">
                                <h5>General Dermatology</h5>
                                <p>With the help of advanced diagnostic tools, our outstanding certified dermatologists
                                    are popular for accurate clinical diagnosis and appropriate management. </p>

                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 mt-5">
                    <a routerLinkActive="active" [routerLink]="['/cosmetic-treatment']" class="service-a">
                        <div class="service-overlay-area">
                            <div class="services-item service-overlay-item">
                                <img src="assets/img/services-ic.png" width="50px;">
                                <h5>Cosmetic Treatments</h5>
                                <p>Skin and face - We expertise ourselves in providing aesthetic and cosmetic treatments
                                    like Chemical peeling, Botox, Filler, Non surgical face lift, PRP/Vampire facial,
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 mt-5">
                    <a outerLinkActive="active" [routerLink]="['/psychodermatology']" class="service-a">
                        <div class="service-overlay-area">
                            <div class="services-item service-overlay-item">
                                <img src="assets/img/services-ic3.png" width="50px;">
                                <h5> Psychodermatology & Palliative Dermatology</h5>
                                <p>Mind has immense role in skin functions. Emotions can cause or exacerbate skin
                                    disorders.
                                    There are a handful of skin disorders which require detailed </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-3 mt-5">
                    <a outerLinkActive="active" [routerLink]="['/sexually-disease']" class="service-a">
                        <div class="service-overlay-area">
                            <div class="services-item service-overlay-item">
                                <img src="assets/img/services-ic4.png" width="50px;">
                                <h5>STD (Sexually Transmitted Disease)</h5>
                                <p>We are the top STD centre in the region offering absolute confidentiality Screening
                                    for
                                    Sexually transmitted infections, Counselling for venereophobia, </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>





    <section class="about-area mt-50 sub-title">
        <div class="container">
            <h2 class="text-center">About</h2>
            <h6 class="text-center">REVIVE SKIN CLINIC</h6>
            <h3 class="text-center">Dr. Hafi's Revive Skin Clinic: Where Science Meets Beauty in Kozhikode</h3>
            <div class="row">
                <div class="col-lg-6 mt-2">
                    <img src="assets/img/about-home.jpg" class="img-fluid">
                </div>

                <div class="col-lg-6">
                    <div class="about-content-area">
                        <p>Unveil your most confident self with healthy, radiant skin. Welcome to Revive Skin Clinics in
                            Kozhikode, your premier destination for comprehensive dermatological care. Our team of
                            board-certified dermatologists possesses a wealth of experience and knowledge, constantly
                            staying at the forefront of advancements in the field. We are passionate about guiding you
                            on your journey to achieving and maintaining optimal skin health.</p>
                        <p>Revive Skin Clinics provides an extensive range of services to address an array of concerns.
                            Our general dermatology services address common problems that include acne, eczema, and
                            psoriasis, while our cosmetic dermatology treatments could assist in making you seem more
                            young and revitalized. Concerned about hair loss? Our professional hair clinic provides
                            efficient remedies for thinning hair and hair growth.</p>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <p>We acknowledge that skin health is more than merely surface-level. That is why we take a
                    comprehensive approach to your treatment. Our psychodermatology services emphasize the psychological
                    aspects of skin disorders, acknowledging the importance of the mind-body connection in aiding
                    recovery. Our caring and skilled team provides palliative dermatological treatment to patients with
                    chronic diseases to alleviate symptoms and improve quality of life. We also provide private and
                    efficient STD testing and treatment services.</p>
                <p>At Revive Skin Clinics, patient-centered treatment is our top priority. We believe in taking the time
                    to understand your particular needs and issues. We enable you to make educated decisions about your
                    treatment plan by providing individualized consultations and open communication. Our team is
                    committed to providing constant guidance and support throughout your journey.</p>
                <p>Experience the Revive difference. Schedule a consultation today and embark on a path to achieving
                    your healthiest, most beautiful skin.</p>
                <div class="more-button-area mt-4">
                    <a routerLinkActive="active" [routerLink]="['/about']"><button class="more-button">Learn More
                            &nbsp;<i class="fas fa-arrow-right more-arrow"></i></button></a>
                </div>
            </div>
        </div>
    </section>


    <section class="doctors mt-50 sub-title">
        <div class="container">
            <h2 class="text-center">Doctors</h2>
            <div class="row">
                <div class="col-lg-3 mt-5">
                    <div class="doctors-overlay-area">
                        <div class="doctors-content doctors-overlay-item">
                            <img src="assets/img/dr_one.jpg" class="img-fluid">
                            <h5 class="pb-1">Dr. Bishurul Hafi NA</h5>
                            <span>MBBS, MD (DVD), DNB, FECSM</span>
                            <!-- <p>Dr. Bishurul Hafi NA is an expert dermatologist practicing at Revive Skin Clinic...</p> -->
                            <div class="more-button-area ">
                                <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button
                                        class="more-button">Know More &nbsp;<i
                                            class="fas fa-arrow-right"></i></button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-4 mt-5">
                    <div class="doctors-overlay-area">
                        <div class="doctors-content doctors-overlay-item">
                            <img src="assets/img/doc2.jpg" class="img-fluid">
                            <h5 class="pb-1">Dr. Nikhila PK</h5>
                            <span>MBBS MD DNB MNAMS</span>
                            <p>Did MBBS and MD from Govt medical college Kozhikode. Has an experience of 7 years..</p>
                            <div class="more-button-area ">
                                <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button class="more-button">Know More &nbsp;<i class="fas fa-arrow-right"></i></button></a>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-3 mt-5">
                    <div class="doctors-overlay-area">
                        <div class="doctors-content doctors-overlay-item">
                            <img src="assets/img/dr_two.jpg" class="img-fluid">
                            <h5>Dr. Ajina M</h5>
                            <span>MBBS, DDVL</span>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus ultrices vitae urna..</p> -->
                            <div class="more-button-area ">
                                <a routerLinkActive="active" [routerLink]="['/doctor-ajina-detail']"><button
                                        class="more-button">Know More &nbsp;<i
                                            class="fas fa-arrow-right"></i></button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-3 mt-5">
                    <div class="doctors-overlay-area">
                        <div class="doctors-content doctors-overlay-item">
                            <img src="assets/img/doc4.png" class="img-fluid">
                            <h5>John Alexander</h5>
                            <span>MBBS, DDVL</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus ultrices vitae urna.. </p>
                            <div class="more-button-area ">
                                <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button class="more-button">Know More &nbsp;<i class="fas fa-arrow-right"></i></button></a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>

    <section id="docters-mobile" class="docters-mobile">
        <h2 class="text-center">Doctors</h2>
        <div class="docters_slide  ">

            <div class="docters-slider">
                <owl-carousel-o [options]="doctersOptions">

                    <ng-container>

                        <ng-template carouselSlide>
                            <div class="single-docters-card ">
                                <div class="doctors-overlay-area">
                                    <div class="doctors-content doctors-overlay-item">
                                        <img src="assets/img/dr_one.jpg" class="img-fluid">
                                        <h5 class="pb-1">Dr. Bishurul Hafi NA</h5>
                                        <span>MBBS, MD (DVD), DNB, FECSM</span>
                                        <!-- <p>Dr. Bishurul Hafi NA is an expert dermatologist practicing at Revive Skin Clinic.</p> -->
                                        <div class="more-button-area ">
                                            <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button
                                                    class="more-button">Know More &nbsp;<i
                                                        class="fas fa-arrow-right"></i></button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <!-- <ng-template carouselSlide>
                    <div class="single-docters-card">
                        <div class="doctors-overlay-area">
                            <div class="doctors-content doctors-overlay-item">
                                <img src="assets/img/doc2.jpg" class="img-fluid">
                                <h5 class="pb-1">Dr. Nikhila PK</h5>
                                <span>MBBS MD DNB MNAMS</span>
                                <p>Did MBBS and MD from Govt medical college Kozhikode. Has an experience of 7 years in
                                    dermatology.</p>
                                <div class="more-button-area ">
                                    <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button class="more-button">Know More &nbsp;<i class="fas fa-arrow-right"></i></button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                  </ng-template> -->
                        <ng-template carouselSlide>
                            <div class="single-docters-card">
                                <div class="doctors-overlay-area">
                                    <div class="doctors-content doctors-overlay-item">
                                        <img src="assets/img/dr_two.jpg" class="img-fluid">
                                        <h5>Dr. Ajina M</h5>
                                        <span>MBBS, DDVL</span>
                                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus ultrices vitae urna id
                                    lectus.</p> -->
                                        <div class="more-button-area ">
                                            <a routerLinkActive="active" [routerLink]="['/doctor-ajina-detail']"><button
                                                    class="more-button">Know More &nbsp;<i
                                                        class="fas fa-arrow-right"></i></button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!-- <ng-template carouselSlide>
                  <div class="single-docters-card ">
                    <div class="doctors-overlay-area">
                        <div class="doctors-content doctors-overlay-item">
                            <img src="assets/img/doc4.png" class="img-fluid">
                            <h5>John Alexander</h5>
                            <span>MBBS, MD (DVD), DNB, FECSM</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus ultrices vitae urna id
                                lectus. </p>
                                <div class="more-button-area ">
                                    <a routerLinkActive="active" [routerLink]="['/doctor-detail']"><button class="more-button">Know More &nbsp;<i class="fas fa-arrow-right"></i></button></a>
                                </div>
                        </div>
                    </div>
                  </div>
                </ng-template> -->


                    </ng-container>


                </owl-carousel-o>

            </div>

        </div>


    </section>



    <section class="our-blog-area sub-title mt-50">
        <div class="container">
            <h2 class="text-center">Blogs</h2>
            <div class="row ">
                <div class="col-lg-4 mt-5 " *ngFor="let team of blogList.slice(0,2); ;let i=index;">

                    <div class="blog-details">
                        <a style="text-decoration: none;" [routerLink]="['/blog-detail/',team.id]"> <img
                                src="{{team.image_url}}" class="img-fluid"></a>
                        <a style="text-decoration: none;" [routerLink]="['/blog-detail/',team.id]">
                            <h5>{{team.title}}</h5>
                        </a>
                        <p
                            style="color: rgba(0, 0, 0, 0.7);
                            font-size: 13px !important;font-family: Sora Regular;    margin-top: 0.5rem;margin-bottom: 0.5rem;">
                            {{team.created_at | date :'dd MMM yyyy'}}</p>
                        <a style="text-decoration: none;" [routerLink]="['/blog-detail/',team.id]">
                            <p [innerHTML]="(team.description | slice: 0:250) ">
                        </a>

                    </div>

                </div>
                <div class="col-lg-4 ">
                    <div class="blog-right d-flex mt-5  " *ngFor="let bl of blogList.slice(2,5); ;let i=index;">
                        <a style="text-decoration: none;" [routerLink]="['/blog-detail/',bl.id]">
                            <div class="blog-right-img">
                                <img src="{{bl.image_url}}">
                            </div>
                        </a>
                        <a style="text-decoration: none;" [routerLink]="['/blog-detail/',bl.id]">
                            <div class="blog-right-details">
                                <h5>{{bl.title}}</h5>
                                <span>{{bl.created_at | date :'dd MMM yyyy'}}</span>

                            </div>
                        </a>
                    </div>

                </div>

            </div>
        </div>
    </section>

    <section class="blogs-slider">
        <h2 class="text-center">Our Latest Blog</h2>
        <div class="">
            <owl-carousel-o [options]="blogsOptions">

                <ng-container *ngFor="let team of blogList ;let i=index;">

                    <ng-template carouselSlide>
                        <div class="single-blogs-card ">
                            <div class="blog-details">
                                <a [routerLink]="['/blog-detail/',team.id]"><img src="{{team.image_url}}"
                                        class="img-fluid"></a>
                                <h5>{{team.title}}</h5>
                                <p
                                    style="color: rgba(0, 0, 0, 0.7);
                            font-size: 13px !important;font-family: Sora Regular;    margin-top: 0.5rem;margin-bottom: 0.5rem;">
                                    {{team.created_at | date :'dd MMM yyyy'}}</p>
                                <p [innerHTML]="(team.description | slice: 0:250) ">

                            </div>
                        </div>
                    </ng-template>

                </ng-container>


            </owl-carousel-o>

        </div>
    </section>



    <section class="publications-area sub-title mt-50">
        <div class="container">
            <h2 class="text-center">Publications</h2>
            <div class="row mt-4">
                <div class="col-lg-6 mt-4">
                    <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7744916/
                    " target="_blank">
                        <div class="publications-details overlay-area home-publi">
                            <p>Hafi B, Uvais NA. Palliative Dermatology- An area of care yet to be explored. Progress in
                                Dermatology</p>
                            <div class="overlay">
                                <div class="overlay-text">Know More</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-6 mt-4">
                    <a href="https://globalizationandhealth.biomedcentral.com/articles/10.1186/s12992-020-00589-w
                    " target="_blank">
                        <div class="publications-details overlay-area home-publi">
                            <p>Uvais NA, Nalakath MJ, Pichen S, Hafi B, et al. Depression, Anxiety, and Coping During
                                the COVID-19 Pandemic Among Indian Expats in the Middle East: A Survey Study. The
                                Primary Care Companion to The Journal of Clinical Psychiatry 2021.</p>
                            <div class="overlay">
                                <div class="overlay-text">Know More</div>
                            </div>
                        </div>
                    </a>
                </div>


            </div>
            <div class="row">
                <div class="col-lg-6 mt-4">
                    <a href="http://www.odermatol.com/odermatology/2020e/E245.Histopathological-BachaspatimayumR.pdf
                    " target="_blank">
                        <div class="publications-details overlay-area home-publi">
                            <p>Bachaspatimayum R, NA BishurulHafi , Thingujam B. Histopathological examination and
                                evaluation of serum vitamin D levels in alopecia-is it necessary? Our Dermatol Online.
                                20203.</p>
                            <div class="overlay">
                                <div class="overlay-text">Know More</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-6 mt-4">
                    <a href="http://www.odermatol.com/odermatology/20183/9.HIV-BachaspatimayumR.pdf
                    " target="_blank">
                        <div class="publications-details overlay-area home-publi">
                            <p>Bachaspatimayum R, Hafi B. A case of HIV, leprosy co- infection- presenting as immune
                                reconstitution inflammatory syndrome. Our Dermatol Online 2018.</p>
                            <div class="overlay">
                                <div class="overlay-text">Know More</div>
                            </div>
                        </div>
                    </a>
                </div>


            </div>
            <div class="publication-button-area text-center mt-5">
                <a routerLinkActive="active" [routerLink]="['/publication']"><button class="publication-btn">View All
                        &nbsp; &nbsp;<img src="assets/img/arrow-blue.png"></button></a>
            </div>
        </div>
    </section>



    <section class="video-gallery sub-title mt-50">
        <div class="container">
            <h2 class="text-center" style="margin-bottom: 80px;">Video Gallery</h2>
            <div class="row mt-5">
                <owl-carousel-o [options]="vedioGallery">

                    <ng-container *ngFor="let gallery of galleryList">

                        <ng-template carouselSlide>
                            <div class="single-docters-card" style="padding-right: 1rem;">
                                <div class="video-area video-mask" style="width: 100%;">
                                    <iframe class="Testimonials-video" [src]="gallery.convertedURL"
                                        title="{{gallery.title}}" frameborder="0" allowfullscreen style="width: 100%;">
                                    </iframe>
                                    <div class="video-btn-area">
                                        <a id="play-video" [src]="gallery.convertedURL">
                                            <span></span>
                                        </a>

                                    </div>
                                </div>
                                <div class="video-text">
                                    <h5>{{gallery.title}}</h5>
                                    <!-- <p>Lorem ipsum dolor sit amet, </p> -->
                                </div>
                            </div>
                            <!-- <div class="item" style="padding-right: 1rem">
                               
                              </div> -->
                        </ng-template>


                    </ng-container>


                </owl-carousel-o>

                <!-- <div class="col-lg-3 mt-5">
                    <div class="video-area">
                        <img src="assets/img/video2.jpg" class="img-fluid">
                        <div class="video-btn-area">
                            <a id="play-video" class="video-play-button" href="#">
                                <span></span>
                            </a>

                        </div>
                    </div>
                    <div class="video-text">
                        <h5>Lorem ipsum dolor sit </h5>
                        <p>Lorem ipsum dolor sit amet, </p>
                    </div>
                </div>
                <div class="col-lg-3 mt-5">
                    <div class="video-area">
                        <img src="assets/img/video1.jpg" class="img-fluid">
                        <div class="video-btn-area">
                            <a id="play-video" class="video-play-button" href="#">
                                <span></span>
                            </a>

                        </div>
                    </div>
                    <div class="video-text">
                        <h5>Lorem ipsum dolor sit </h5>
                        <p>Lorem ipsum dolor sit amet, </p>
                    </div>
                </div>
                <div class="col-lg-3 mt-5">
                    <div class="video-area">
                        <img src="assets/img/video2.jpg" class="img-fluid">
                        <div class="video-btn-area">
                            <a id="play-video" class="video-play-button" href="#">
                                <span></span>
                            </a>

                        </div>
                    </div>
                    <div class="video-text">
                        <h5>Lorem ipsum dolor sit </h5>
                        <p>Lorem ipsum dolor sit amet, </p>
                    </div>
                </div> -->


            </div>
            <div class="publication-button-area text-center mt-5">
                <a routerLinkActive="active" [routerLink]="['/video-gallery']"><button class="publication-btn">View All
                        &nbsp; &nbsp;<img src="assets/img/arrow-blue.png"></button></a>
            </div>
        </div>
    </section>

    <section id="gallery-mobile" class="gallery-mobile">
        <h2 class="text-center">Video Gallery</h2>
        <div class="gallery_slide  ">

            <div class="gallery-slider">
                <owl-carousel-o [options]="doctersOptions">

                    <ng-container *ngFor="let gallery of galleryList">

                        <ng-template carouselSlide>
                            <div class="single-docters-card ">
                                <div class="video-area">
                                    <iframe class="Testimonials-video" [src]="gallery.convertedURL"
                                        title="{{gallery.title}}" frameborder="0" allowfullscreen
                                        style="    width: 100%;"></iframe>
                                    <div class="video-btn-area">
                                        <a id="play-video" [src]="gallery.convertedURL">
                                            <span></span>
                                        </a>

                                    </div>
                                </div>
                                <div class="video-text">
                                    <h5>{{gallery.title}}</h5>
                                    <!-- <p>Lorem ipsum dolor sit amet, </p> -->
                                </div>
                            </div>
                        </ng-template>



                    </ng-container>


                </owl-carousel-o>

            </div>

        </div>
        <div class="publication-button-area text-center mt-5">
            <a routerLinkActive="active" [routerLink]="['/video-gallery']"><button class="publication-btn">View All
                    &nbsp; &nbsp;<img src="assets/img/arrow-blue.png"></button></a>
        </div>


    </section>






    <section class="facilities sub-title mt-50">
        <div class="container">
            <h2 class="text-center">Facilities</h2>
            <div class="row">

                <owl-carousel-o [options]="facilitiesOptions">

                    <ng-container>

                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Consultation Room.jpg" class="img-fluid">
                                            <h5 class="fac_title">Consultation Room</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Laser Room.jpg" class="img-fluid">
                                            <h5 class="fac_title">Laser Room</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Pharmacy.jpg" class="img-fluid">
                                            <h5 class="fac_title">Pharmacy</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Procedure Room 1.jpg" class="img-fluid">
                                            <h5 class="fac_title">Procedure Room I </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Procedure Room 2.jpg" class="img-fluid">
                                            <h5 class="fac_title">Procedure Room II</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Reception.jpg" class="img-fluid">
                                            <h5 class="fac_title">Reception</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Rejuvenation Room.jpg" class="img-fluid">
                                            <h5 class="fac_title">Rejuvenation Room</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-facilities-card ">
                                <div class="overlay-about">
                                    <div class="facilities-item">
                                        <div class="content-section">
                                            <img src="assets/img/Facilities/Waiting Room.jpg" class="img-fluid">
                                            <h5 class="fac_title">Waiting Room</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>


                    </ng-container>

                </owl-carousel-o>



                <!-- <div class="col-lg-4 mt-5">
                    <div class="overlay-about">
                        <div class="facilities-item">
                            <div class="content-section">
                                <img src="assets/img/Facilities/Waiting Room.jpg" class="img-fluid">
                                <h5 class="fac_title">Waiting Room</h5>
                            </div>
                        </div>
                    </div>

                </div> -->




            </div>
            <div class="publication-button-area text-center mt-5">
                <a routerLinkActive="active" [routerLink]="['/facilities']"><button class="publication-btn">View All
                        &nbsp; &nbsp;<img src="assets/img/arrow-blue.png"></button></a>
            </div>
        </div>
    </section>



    <section class="enquiry-area mt-100">
        <div class="container">
            <!-- <div class="enquiry-item">
            <img src="img/enquiry.png" class="img-fluid">
            <div class="row">
              <div class="col-lg-6 col-6">
            <div class="women-area">
              <img src="img/women.png" class="img-fluid">
            </div>
              </div>

            <div class="col-lg-6">
                <div class="enquiry-content">
                    <h2>hhhhhhh</h2>
                </div>
            </div>              

          </div>
        </div> -->

            <div class="enquiry-main">
                <div class="row">
                    <div class="col-lg-6">
                        <img src="assets/img/women.png" class="img-fluid enquiry-img">
                    </div>

                    <div class="col-lg-6">
                        <div class="enquiry-item">
                            <h2>WE OFFER A WIDE RANGE OF PROCEDURES TO HELP YOU GET THE PERFECT SMILE.</h2>
                            <p>Get in touch now!</p>
                            <a routerLinkActive="active" [routerLink]="['/contact']"><button
                                    class="enquiry-btn mt-2">ENQUIRY</button></a>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </section>


    <section class="faq-home mt-50">
        <div class="container">
            <h2 class="text-center mt-4">FAQ</h2>

            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            1. What services does Revive Skin Clinics offer?
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Revive Skin Clinics provides a wide range of services including general dermatology for skin
                            conditions like acne, eczema, and psoriasis, cosmetic treatments such as Botox, dermal
                            fillers, and chemical peels, specialized hair clinic services for hair loss and scalp
                            conditions, palliative dermatology for managing symptoms associated with chronic skin
                            conditions, and confidential consultations and treatments for sexually transmitted diseases
                            (STDs).
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            2. What general dermatology services are available at Revive Skin Clinics? </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Our general dermatology services cover a wide spectrum, including diagnosis and treatment
                            for various skin conditions such as acne, eczema, psoriasis, and skin cancer screenings. We
                            also offer personalized skincare routines and advice for maintaining healthy skin. </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            3. Can lifestyle changes help manage acne?
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes, certain lifestyle changes can significantly help manage acne. Maintaining a consistent
                            skincare routine, avoiding heavy cosmetics, eating a balanced diet, and managing stress are
                            crucial. Our dermatologists at Revive Skin Clinics can guide you on effective lifestyle
                            adjustments alongside medical treatments for optimal results. </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            4. What specialized services does the hair clinic at Revive Skin Clinics offer? </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Our hair clinic specializes in diagnosing and treating hair loss, scalp conditions, and
                            providing hair restoration solutions. We offer treatments such as PRP (Platelet-Rich Plasma)
                            therapy, hair transplants, and medical management for conditions like alopecia. </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            5. Does Revive Skin Clinics provide palliative dermatology services? </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes, we offer palliative dermatology services aimed at providing relief and improving the
                            quality of life for patients with chronic or terminal skin conditions. Our compassionate
                            team works closely with patients and caregivers to manage symptoms and maintain skin health.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            6. What STD screening and treatment options are available at Revive Skin Clinics?                         </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            We provide confidential STD screenings for a range of sexually transmitted infections, including HIV, syphilis, gonorrhea, and chlamydia. Our clinic offers comprehensive testing, prompt diagnosis, and personalized treatment plans to ensure the health and well-being of our patients.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            7. How safe are the cosmetic procedures at Revive Skin Clinics?                        </button>
                    </h2>
                    <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            The cosmetic procedures at Revive Skin Clinics are performed by highly trained professionals using state-of-the-art equipment and techniques. Patient safety is our top priority, and we adhere to strict safety protocols to ensure all treatments are safe and effective.                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            8. What treatments are available at Revive Hair Clinic for hair loss?                        </button>
                    </h2>
                    <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Revive Hair Clinic offers a range of hair loss treatments including PRP (Platelet-Rich Plasma) therapy, hair transplantation, laser therapy, and medication to stimulate hair growth and restore hair density.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            9. Can children receive dermatology care at Revive?                        </button>
                    </h2>
                    <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes, Revive Skin Clinics provides dermatology care for patients of all ages, including children, for a variety of skin conditions such as eczema, rashes, and acne.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            10. How effective is laser hair removal at Revive Skin Clinics?                        </button>
                    </h2>
                    <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Laser hair removal at Revive Skin Clinics is highly effective for reducing unwanted hair. Multiple sessions are usually required to achieve optimal results, and many patients experience long-term hair reduction, enjoying smoother skin without the need for frequent shaving or waxing.                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseEone" aria-expanded="false" aria-controls="collapseEone">
                            11. How long do treatments typically take?                         </button>
                    </h2>
                    <div id="collapseEone" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            The duration of treatments at Revive Skin Clinics varies depending on the procedure. Simple treatments like chemical peels or microdermabrasion may take about 30-45 minutes, while more complex procedures such as laser therapies or hair transplants can take several hours. The clinic ensures efficient scheduling to minimize wait times.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTone" aria-expanded="false" aria-controls="collapseTone">
                            12. What is the cost of treatments at Revive Skin Clinics?                        </button>
                    </h2>
                    <div id="collapseTone" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            The cost of treatments at Revive Skin Clinics varies based on the specific procedure and individual requirements. The clinic offers competitive pricing and transparent cost breakdowns during the initial consultation. Patients can also inquire about available packages and financing options to make their treatments more affordable.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThreeOne" aria-expanded="false" aria-controls="collapseThreeOne">
                            13. What should I expect during my first consultation?                         </button>
                    </h2>
                    <div id="collapseThreeOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            During your first consultation at Revive Skin Clinics, you will undergo a thorough skin analysis conducted by a qualified dermatologist. This includes discussing your medical history, current skin concerns, and desired outcomes. Based on this information, a personalized treatment plan will be created to address your specific needs effectively.                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFourOne" aria-expanded="false" aria-controls="collapseFourOne">
                            14. Are the treatments at Revive Skin Clinics safe?                        </button>
                    </h2>
                    <div id="collapseFourOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes, the treatments at Revive Skin Clinics are highly safe. The clinic employs experienced dermatologists and certified professionals who use state-of-the-art equipment and follow stringent safety protocols. All procedures are conducted in a sterile environment, ensuring patient safety and optimal results.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFiveOne" aria-expanded="false" aria-controls="collapseFiveOne">
                            15. What makes Revive Skin Clinics unique compared to other dermatology clinics?                        </button>
                    </h2>
                    <div id="collapseFiveOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Revive Skin Clinics stands out due to our comprehensive approach, advanced technology, personalized care, and our dedicated team of specialists who provide expert treatment across a wide range of dermatological and cosmetic concerns.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="location-area sub-title mt-50">
        <div class="container">
            <h2 class="text-center">Reach Us</h2>
            <div class="row justify-content-center">
                <div class="col-lg-12 mt-5">
                    <div class="location-details d-flex">
                        <img src="assets/img/location-ic.png" height="22px;" width="20px;">
                        <p>Kannakandi Mansion, Police station, near Nadakkav, Kozhikode, Kerala 673006</p>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-lg-3 mt-2">
                    <div class="location-details d-flex">
                        <img src="assets/img/location-ic2.png" height="22px;" width="20px;">
                        <p class="tel_feild"> <a href="tel:+919995606163">+91 9995606163</a></p>
                    </div>
                </div>
                <div class="col-lg-3 mt-2">
                    <div class="location-details d-flex">
                        <img src="assets/img/location-ic3.png" height="22px;" width="20px;">
                        <p class="email_feild"> <a href="mailto:bishuru@gmail.com">bishuru@gmail.com</a></p>
                    </div>
                </div>
            </div>
            <div class="gl-map mt-5">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15651.405869815377!2d75.77513!3d11.2723269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65be1207d3887%3A0x4b3f22a0bcee83b8!2sDr.%20Hafi&#39;s%20Revive%20Skin%20Clinic!5e0!3m2!1sen!2sin!4v1678171423093!5m2!1sen!2sin"
                    width="100%" height="430px" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
    </section>








    <!-------- Script start----------->

    <script src="js/jquery.js"></script>

    <script>
        $(document).ready(function () {
            $(".menu-icon").on("click", function () {
                $("nav ul").toggleClass("showing");
            });
        });

        // Scrolling Effect

        $(window).on("scroll", function () {
            if ($(window).scrollTop()) {
                $('nav').addClass('black');
            }

            else {
                $('nav').removeClass('black');
            }
        })
    </script>




    <script src="js/bootstrap.min.js"></script>
    <script src="https://kit.fontawesome.com/7c950358d3.js"></script>

    <script>
        function myFunction() {
            location.reload();
        }
    </script>


    <!-- <script>
var lFollowX = 0,
    lFollowY = 0,
    x = 0,
    y = 0,
    friction = 1 / 30;

function moveBackground() {
  x += (lFollowX - x) * friction;
  y += (lFollowY - y) * friction;
  
  translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

  $('.banner-bg-img').css({
    '-webit-transform': translate,
    '-moz-transform': translate,
    'transform': translate
  });

  window.requestAnimationFrame(moveBackground);
}

$(window).on('mousemove click', function(e) {

  var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
  var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
  lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
  lFollowY = (10 * lMouseY) / 100;

});

moveBackground();
</script> -->


    <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>


    <script>
        AOS.init();
    </script>





    <script>
        function myFunction() {
            var x = document.getElementById("myLinks");
            if (x.style.display === "block") {
                x.style.display = "none";
            } else {
                x.style.display = "block";
            }
        }
    </script>

</body>
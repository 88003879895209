<footer class="footer-bg bg-black mt-50">
    <div class="footer-main">
        <div class="container">
            <div class="row justify-content-around">

                <div class="col-lg-2 col-md-6">
                    <div class="footer-content-item text-start">
                        <div class="footer-l d-flex justify-content-lg-start justify-content-center">

                            <img src="assets/img/logo.png">
                        </div>

                    </div>

                </div>


                <div class="col-lg-4">
                    <div class="footer-links">
                        <ul class=" link-item d-flex">
                         

                         
                                <!-- <h5>Getlink</h5>                               -->
                                <li><a class="foot-link link-a" routerLinkActive="active" [routerLink]="['/about']" >About Us</a></li>
                                <li><a class="foot-link link-a" routerLinkActive="active" [routerLink]="['/service-list']" >Services</a> </li>
                                <li><a class="foot-link link-a" routerLinkActive="active" [routerLink]="['/contact']" >Contact</a></li>
                          
                         
                               
                           
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="footer-links d-flex mt-3 justify-content-center">

                    <li><a class="foot-link" href="https://www.facebook.com/ReviveSkinClinicClt" target="_blank" ><i class="fa fa-facebook footer-social" aria-hidden="true"></i></a> </li>
                   
                    <li> <a class="foot-link" href="https://www.instagram.com/revive_skin_clinic_/?hl=en" target="_blank" ><i class="fa fa-instagram footer-social" aria-hidden="true"></i></a> </li>
                    <li> <a class="foot-link" href="https://www.youtube.com/@dr.bishurulhafi8375" target="_blank"><i class="fa fa-youtube-play footer-social" aria-hidden="true"></i>
                    </a> </li>
                    </div>
                </div>


                <div class="col-lg-3 mt-3">
                    <div class="copyright">
                        <p>© 2023 Revive | All right reserved  </p>
                    </div>
                </div>


            </div>

            <div class="mt-2 mb-2 eximuz text-center">
            
                Powered by
                <a href="http://eximuz.com/" target="_blank">
                    <img src="assets/img/eximuz-logo.png" alt="Eximuz" class="img-responsive">
                    EXIMUZ TECHNOLABS
                </a>

            </div>

        </div>


        <!--       <div class="copyright">
      <div class="container">

          <div class="copyright-item">
          <div class="row justify-content-between">
            <div class="col-md-6">
              <p></p>
            </div>
          
          </div>
        </div>

      </div>  

  </div> -->


    </div>
</footer>

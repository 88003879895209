<div class="bread-crumb mt-5">
    <div class="container">
        <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <li><a routerLinkActive="active" [routerLink]="['/service-list']">Services</a></li>
            <li>STD (Sexually Transmitted Disease)</li>
        </ul>
    </div>
</div>




<section class="service-details-area sub-title">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 mt-5">
                <div class="service-details-content">
                    <img src="assets/img/5.-What is an unconsummated marriage.jpg" class="img-fluid">
                    <h2>STD (Sexually Transmitted Disease)</h2>
                    <p>
                        We are the top STD center in the region offering absolute confidentiality Screening for Sexually
                        transmitted infections, Counselling for venereophobia, Treatment for Genital warts, Herpes
                        genitalis, Balanoposthitis, Gonorrhea, Chlamydia, Syphilis, Bacterial and fungal infections for
                        men and women.
                        Sexology clinic- Amore Centre for Sexual Medicine- Best sexual health clinic in Kerala for
                        everyone.

                    </p>


                </div>
            </div>








            <div class="col-lg-4 mt-5">
                <h5 style="color: #20618E; font-size: 20px;">Other Services</h5>
                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/dermatology']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/ACNE.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>General Dermatology</h5>

                        </div>

                    </div>
                </a>
                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/cosmetic-treatment']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/Skin tag - Wart- DPN Removal.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Cosmetic Treatment</h5>

                        </div>

                    </div>
                </a>

                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/hair-clinic']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/Hair Transplant.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Hair Clinic</h5>

                        </div>

                    </div>
                </a>

                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/psychodermatology']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY (2).jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Psychodermatology & Palliative Dermatology</h5>

                        </div>

                    </div>
                </a>
                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/amore-centre']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/amore.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Amore Centre For Sexual Medicine And Relationship Therapy</h5>

                        </div>

                    </div>
                </a>


            </div>
        </div>
    </div>
</section>

<section class="faq-home mt-50">
    <div class="container">
        <h2 class="text-center mt-4">FAQ</h2>

        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        1. What treatment options are available for STDs at Revive Skin Clinics? 
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Treatment options for STDs at Revive Skin Clinics depend on the specific infection. Bacterial
                        infections like chlamydia and gonorrhea are typically treated with antibiotics, while viral
                        infections like herpes and HIV are managed with antiviral medications. Our team will develop a
                        personalized treatment plan based on your diagnosis. 
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2. What should I expect during an STD screening? 
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        During an STD screening at Revive Skin Clinics, you will undergo a thorough medical history
                        review and physical examination. Depending on the symptoms and risk factors, tests may include
                        blood tests, urine samples, or swabs of affected areas. Our team will explain the process and
                        answer any questions you may have. 
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            3. What types of STDs can be diagnosed and treated at Revive Skin Clinics? 
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Revive Skin Clinics in Kozhikode offers comprehensive diagnosis and treatment for a wide
                            range of sexually transmitted diseases (STDs), including chlamydia, gonorrhea, syphilis,
                            herpes, human papillomavirus (HPV), HIV, and hepatitis B and C. Our experienced
                            dermatologists provide confidential and professional care to address all STD-related
                            concerns. 
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            4. Are STD tests at Revive Skin Clinics confidential? 
                        </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes, all STD tests and consultations at Revive Skin Clinics are strictly confidential. We
                            prioritize your privacy and ensure that your personal and medical information is securely
                            protected.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            5. How can I schedule an appointment for STD testing at Revive Skin Clinics? 
                        </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            You can schedule an appointment for STD testing at Revive Skin Clinics by calling our clinic
                            or booking online through our website. We ensure a discreet and comfortable environment for
                            all our patients, and appointments can be made at your convenience. 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
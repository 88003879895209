import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './pages/about/about.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ContactComponent } from './pages/contact/contact.component';

import { FacilitiesComponent } from './pages/facilities/facilities.component';
import { HomeComponent } from './pages/home/home.component';
import { PublicationComponent } from './pages/publication/publication.component';
import { ServiceListComponent } from './pages/service-list/service-list.component';

import { VideoGalleryListComponent } from './pages/video-gallery-list/video-gallery-list.component';
import { CosmeticTreatmentComponent } from './pages/cosmetic-treatment/cosmetic-treatment.component';
import { HairClinicComponent } from './pages/hair-clinic/hair-clinic.component';
import { PsychodermatologyComponent } from './pages/psychodermatology/psychodermatology.component';
import { SexuallyDiseaseComponent } from './pages/sexually-disease/sexually-disease.component';
import { AmoreCentreComponent } from './pages/amore-centre/amore-centre.component';
import { DocterDetailComponent } from './pages/docter-detail/docter-detail.component';
import { DermatologyComponent } from './pages/dermatology/dermatology.component';
import { NikhilaDoctorDetailComponent } from './pages/nikhila-doctor-detail/nikhila-doctor-detail.component';
import { DoctorAjinaDetailComponent } from './pages/doctor-ajina-detail/doctor-ajina-detail.component';


const routes: Routes = [
  {  path: '',
  redirectTo: 'home',
  pathMatch: 'full',
     },
  { path: 'home', component: HomeComponent,
  data: {
    seo: {
      title: 'Best dermatologist in kerala | Skin specialist in calicut',
      metaTags: [
      { name: 'description', content: 'Get the best dermatological treatments in Kerala. Skin Cosmetic Clinic in Calicut provides world-class skin care specialist.We also have specialized sexologists' },
        { property: 'og:title', content: 'Best dermatologist in kerala | Skin specialist in calicut'},
      
      ]
    }
  } },


  { path: 'service-list', component: ServiceListComponent,
  data: {
    seo: {
      title: 'best skin specialist in calicut | skin specialist in calicut',
      metaTags: [
      { name: 'description', content: 'Looking for the best skin specialist in Calicut? Get the best advice, diagnosis, and treatment from our highly experienced dermatologists. best skin specialist' },
        { property: 'og:title', content: 'best skin specialist in calicut | skin specialist in calicut'},
      
      ]
    }
  } },

  
  { path: 'cosmetic-treatment', component: CosmeticTreatmentComponent,
  data: {
    seo: {
      title: 'Best cosmetic clinic in kerala | Cosmetic clinic in calicut',
      metaTags: [
      { name: 'description', content: 'Looking for the best cosmetic clinic in Kerala? At Cosmetic Clinic Calicut, we provide world-class cosmetic surgery and treatments using advanced techniques.' },
        { property: 'og:title', content: 'Best cosmetic clinic in kerala | cosmetic clinic in calicut'},
      
      ]
    }
  } },


  { path: 'sexually-disease', component: SexuallyDiseaseComponent,
  data: {
    seo: {
      title: 'Sexologist in Calicut | Best sexologist in Calicut',
      metaTags: [
      { name: 'description', content: 'Reviveskinclinic provides comprehensive sexual healthcare services with expert sexologists in Calicut.Offering treatment for all types of sexual problems.' },
        { property: 'og:title', content: 'Sexologist in calicut | Best sexologist in calicut'},
      
      ]
    }
  } },
  
  
  { path: 'dermatology', component: DermatologyComponent,
  data: {
    seo: {
      title: 'Best dermatologist in Calicut | Dermatologist in Kerala',
      metaTags: [
      { name: 'description', content: 'Looking for the best dermatologist in Calicut, Kerala? We Dermatology offers the latest treatments and care with a focus on patient safety & satisfaction.' },
        { property: 'og:title', content: 'best dermatologist in calicut | dermatologist in kerala'},
      
      ]
    }
  } },

  { path: 'about', component: AboutComponent },
  { path: 'facilities', component: FacilitiesComponent },
  { path: 'video-gallery', component: VideoGalleryListComponent },
  { path: 'publication', component: PublicationComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'blog-detail/:id', component: BlogDetailComponent },
  { path: 'doctor-detail', component: DocterDetailComponent },
  { path: 'doctor-nikhila-detail', component: NikhilaDoctorDetailComponent },
  { path: 'doctor-ajina-detail', component: DoctorAjinaDetailComponent },
  { path: 'hair-clinic', component: HairClinicComponent },
  { path: 'psychodermatology', component: PsychodermatologyComponent },
  { path: 'amore-centre', component: AmoreCentreComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

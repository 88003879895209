import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { HeaderComponent } from './include/header/header.component';
import { FooterComponent } from './include/footer/footer.component';

import { FacilitiesComponent } from './pages/facilities/facilities.component';
import { VideoGalleryListComponent } from './pages/video-gallery-list/video-gallery-list.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PublicationComponent } from './pages/publication/publication.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceListComponent } from './pages/service-list/service-list.component';
import { DocterDetailComponent } from './pages/docter-detail/docter-detail.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SexuallyDiseaseComponent } from './pages/sexually-disease/sexually-disease.component';
import { AmoreCentreComponent } from './pages/amore-centre/amore-centre.component';
import { HairClinicComponent } from './pages/hair-clinic/hair-clinic.component';
import { CosmeticTreatmentComponent } from './pages/cosmetic-treatment/cosmetic-treatment.component';
import { PsychodermatologyComponent } from './pages/psychodermatology/psychodermatology.component';
import { DatePipe } from '@angular/common';
import { DermatologyComponent } from './pages/dermatology/dermatology.component';
import { NikhilaDoctorDetailComponent } from './pages/nikhila-doctor-detail/nikhila-doctor-detail.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DoctorAjinaDetailComponent } from './pages/doctor-ajina-detail/doctor-ajina-detail.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
  
    FacilitiesComponent,
    VideoGalleryListComponent,
    BlogsComponent,
    ContactComponent,
    PublicationComponent,
    BlogDetailComponent,
    ServiceListComponent,
    DocterDetailComponent,
    SexuallyDiseaseComponent,
    AmoreCentreComponent,
    HairClinicComponent,
    CosmeticTreatmentComponent,
    PsychodermatologyComponent,
    DermatologyComponent,
    NikhilaDoctorDetailComponent,
    DoctorAjinaDetailComponent

  ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxPaginationModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(), 
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="bread-crumb mt-5">
    <div class="container">
        <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <li>Video Gallery</li>
        </ul>
    </div>
</div>


<section class="video-gallery-list">
    <div class="container">
        <div class="row justify-content-lg-center">
            <div class="col-lg-3 col-6 mt-5 video_block"  *ngFor="let gallery of galleryList | paginate: { itemsPerPage: 8, currentPage: p }">
                <div class="video-area video-mask">
                    <iframe width="100%" class="Testimonials-video" [src]="gallery.convertedURL"
                    title="{{gallery.title}}" frameborder="0" allowfullscreen></iframe>
                    <div class="vi" [src]="gallery.convertedURL">
                      

                    </div>
                </div>
                <div class="video-text">
                    <h5>{{gallery.title}}</h5>
                    <!-- <p>Lorem ipsum dolor sit amet, </p> -->
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <div class="listing-area mt-5">
    <div class="container">
        <ul class="listing">
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>></li>
            

        </ul>
    </div>
</div> -->

<div class="pagination-area">
    <div class="container">
        <pagination-controls class="my-pagination" (pageChange)="p = $event" class="float-right"></pagination-controls>
    </div>
</div>
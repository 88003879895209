<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li><a routerLinkActive="active" [routerLink]="['/service-list']">Services</a></li>
        <li>Amore Centre For Sexual Medicine and Relationship Therapy</li>
      </ul>
    </div>
</div>




<section class="service-details-area sub-title">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-8 mt-5">
                <div class="service-details-content">
                    <img src="assets/img/Amore Centre For Sexual Medicine and Relationship Therapy.jpg" class="img-fluid">
                    <h2>Amore Centre For Sexual Medicine And Relationship Therapy</h2>
                    <p>
                        This unique center, one of the pioneers in the region, offers the most advanced treatment for all sexual concerns for men and women. We have specialized team for relationship therapy too. Our 360 degree approach towards sexual concerns is applauded widely which put us in number one option for sex solutions.
    
                    </p>
                   
                    
                </div>
            </div>
           


         
            



            <div class="col-lg-4 mt-5">
                <h5 style="color: #20618E; font-size: 20px;">Other Services</h5>
                <a style="text-decoration: none;" routerLinkActive="active" [routerLink]="['/dermatology']">
                <div class="details-right d-flex mt-4">
         
                    <div class="details-right-img">
                        <img src="assets/img/ACNE.jpg">
                    </div>
                    <div class="service-right-details">
                        <h5>General Dermatology</h5>
                       
                    </div>

                </div></a>
                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/cosmetic-treatment']">
                <div class="details-right d-flex mt-4">
                    <div class="details-right-img">
                        <img src="assets/img/Skin tag - Wart- DPN Removal.jpg">
                    </div>
                    <div class="service-right-details">
                        <h5>Cosmetic Treatment</h5>
                       
                    </div>

                </div></a>
              
                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/hair-clinic']">
                <div class="details-right d-flex mt-4">
                    <div class="details-right-img">
                        <img src="assets/img/Hair Transplant.jpg">
                    </div>
                    <div class="service-right-details">
                        <h5>Hair Clinic</h5>
                       
                    </div>

                </div></a>

                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/psychodermatology']">
                <div class="details-right d-flex mt-4">
                    <div class="details-right-img">
                        <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY (2).jpg">
                    </div>
                    <div class="service-right-details">
                        <h5>Psychodermatology & Palliative Dermatology</h5>
                       
                    </div>

                </div></a>

                <a style="  text-decoration: none;" routerLinkActive="active" [routerLink]="['/sexually-disease']">
                <div class="details-right d-flex mt-4">
                    <div class="details-right-img">
                        <img src="assets/img/5.-What is an unconsummated marriage.jpg">
                    </div>
                    <div class="service-right-details">
                        <h5>STD (Sexually Transmitted Disease) </h5>
                       
                    </div>

                </div></a>


            </div>
        </div>
    </div>
</section>



<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li><a routerLinkActive="active" [routerLink]="['/service-list']">Services</a></li>
        <li>Hair Clinic</li>
      </ul>
    </div>
</div>




<section class="service-details-area sub-title">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-8 mt-5">
                <div class="service-details-content">
                    <img src="assets/img/Hair Clinic.jpg" class="img-fluid">
                    <h2>Hair Clinic</h2>
                    <p>
                        Worried about hair loss? Our expert team is well equipped with most updated knowledge and advanced treatment techniques. Our mantra is to personalize the treatment protocol after proper analysis and investigation. Our special preparation of Platelet Rich Plasma (PRP) and Growth Factor Concentrate (GFC) has given hope to hundreds of clients anxious about the hair loss. We also have a highly experienced team of hair transplant experts offering perfect hair line with maximum density.    
                    </p>
                   
                    
                </div>

                <div class="other-datas deskhide">
                    <div class="row">
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Hair Clinic/GFC.png" class="img-fluid">
                            <h5>GFC</h5>
                        </div>
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Hair Clinic/Hair Transplant.png" class="img-fluid">
                            <h5>Hair Transplant</h5>
                        </div>
                        <div class="col-lg-4 other-item mt-4">
                            <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Hair Clinic/PRP.png" class="img-fluid">
                            <h5>PRP</h5>
                        </div>
                </div>
                </div>

            </div>
           


         
            



            <div class="col-lg-4 mt-5">
                <h5 style="color: #20618E; font-size: 20px;">Other Services</h5>
                
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/dermatology']">
                    <div class="details-right d-flex mt-4">
                        <div class="details-right-img">
                            <img src="assets/img/ACNE.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>General Dermatology</h5>
                           
                        </div>
    
                    </div>
                </a>
             
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/cosmetic-treatment']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/Skin tag - Wart- DPN Removal.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Cosmetic Treatments</h5>

                        </div>


                    </div>
                </a>
              
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/psychodermatology']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY (2).jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Psychodermatology & Palliative Dermatology</h5>

                        </div>


                    </div>
                </a>


                <a class="service_tag" routerLinkActive="active" [routerLink]="['/sexually-disease']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/5.-What is an unconsummated marriage.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>STD (Sexually Transmitted Disease)</h5>

                        </div>


                    </div>
                </a>
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/amore-centre']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/amore.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Amore Centre For Sexual Medicine And Relationship Therapy</h5>

                        </div>


                    </div>
                </a>


            </div>
        </div>

        <div class="row">
            <div class="other-datas mobhide">
                <div class="row">
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Hair Clinic/GFC.png" class="img-fluid">
                        <h5>GFC</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Hair Clinic/Hair Transplant.png" class="img-fluid">
                        <h5>Hair Transplant</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/REVIVE SKIN CLINIC_images/Services/Hair Clinic/PRP.png" class="img-fluid">
                        <h5>PRP</h5>
                    </div>
            </div>
            </div>
        </div>


    </div>
</section>


import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(public api:Api) { }

  getblogList(){
    let endpoint='blog'
    return this.api.get(endpoint)
  }


  getBlogView(id: any) {
    let endpoint = 'blog/' + id
    return this
      .api
      .get(endpoint)
  }

}

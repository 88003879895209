import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(public api:Api) { }

  getblogList(){
    let endpoint='blog'
    return this.api.get(endpoint)
  }

  // getblogDetail(){
  //   let endpoint='blog'
  // }

  getpublicationList(){
    let endpoint='publications'
    return this.api.get(endpoint)
  }

  sendContactData(data:any){
    let endpoint='contacts/add'
    return this
    .api
    .post(endpoint,data)
  }

}

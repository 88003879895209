<div class="bread-crumb mt-5">
    <div class="container">
    <ul class="breadcrumb">
        <li><a href="#">Home</a></li>
        <li>Doctors</li>
      </ul>
    </div>
</div>





<section class="doctors-details-list mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="details-item">
                    <img class="dr_img" src="assets/img/dr_one.jpg">
                    <h2>Dr. Bishurul Hafi NA
                    </h2>
                    <span>MBBS, MD (DVD), DNB, FECSM
                    </span>
                    <p class="mt-3">
                      Dr. Bishurul Hafi NA is an expert dermatologist practicing at Revive Skin Clinic. He is a renowned dermatologist not only in Kozhikode, Kerela but patients from every corner of the country contact and visit him. He has worked extensively in the field of Dermatology, Cosmetology, Venerology & Leprosy.
                    </p>
                  <p>
                    During his few years of practice he has successfully treated 3000+ patients. His competence in the field shows through his gentle confidence and the treatments at the clinic are very effective. With excellent academics records, professional association and publication he is very actively involved in his work. He cater services like acne vulgaris, hair loss, skin pigmentation, scars, sexual disorders etc.
                  </p>
  
                  <div class="other-details mt-5">
                    <h2>Achievements</h2>
                    <h5 class="mt-3">EDUCATION :</h5>
                    
                    <ul>
                      <li>FECSM (Fellow European Committee of Sexual Medicine), 2020.</li>
                      <li>DNB (diplomate of National Board) in Dermatology, 2020.</li>
                      <li>MD (Dermatology, Venereology and Leprosy), Regional Institute of Medical Science, Imphal, India, 2018.</li>
                      <li>MBBS, Kozhikode Medical College, 2012.</li>
                      <li>London diploma in psychosexual and relationship therapy (Middlesex university, UK- Ongoing).</li>
                     
  
                    </ul>
  
                    <h5 class="mt-5">PROFESSIONAL ASSOCIATION:</h5>
                    <ul>
                      <li>National Secretary elect- Psychodermatology Association of India (PDAI), 2021-2022.</li>
                      <li>Indian Association of Dermatology, venereology and leprosy (IADVL), life member.</li>
                      <li>International Society of Sexual Medicine (ISSM), general member.</li>
                      <li>South Asian Society of Sexual Medicine (SASSM), general member.</li>
                
  
                    </ul>
  
  
                    <h5 class="mt-5">GRANTS AND AWARDS :</h5>
  
                    <ul>
                      <li>ISSM scholarship- ESSM School of Sexual Medicine, 2019.</li>
             
  
                    </ul>
  
  
                    <h5 class="mt-5">ACADEMIC ACHIEVEMENTS:</h5>
  
                    <ul>
                      <li>International Society of Sexual Medicine (ISSM) scholarship winner to attend European Society of Sexual Medicine (ESSM) school of sexual medicine, Budapest, 2019.</li>
                      <li>Maisnam Gulamjat Singh Memorial Best PG Gold medal award- 2018 for best outgoing PG.</li>
                      <li>Khuraijam Jnanendra Singh Memorial Gold medal award 2018 for 1st position in Dermatology & VL.</li>
                      <li>1st runner up in national level quiz- Indian Society of Pediatric Dermatology Conference- 2017, New Delhi.</li>
                      <li>1st runner up in East India Zone quiz- TYSA young genie quiz by Torrent Pharma- 2017, Kolkata.</li>
                      <li>Finalist in national level quiz- TYSA young genie quiz by Torrent Pharma- 2017, Ahmadabad</li>
                      <li>1st runner up in Northeast India level quiz- IADVL IGNQPP quiz- 2017, Guwahatti</li>
                      <li>Winner in Northeast India level quiz- IADVL IGNQPP quiz- 2018, Imphal</li>
                      <li>Finalist in national level quiz- IADVL IGNQPP quiz- 2018, Kochin</li>
                      <li>Selected for European Society of Sexual Medicine School of sexual medicine in Budhapest, Hungary on Nov 7-17, 2019</li>
  
                    </ul>
  
                  </div>
  
                
                  
                  
  
  
  
                  
                </div>
            </div>
            <div class="col-lg-3 mt-4">
              <h5 style="color: #20618E;">Other Doctors</h5>
                <div class="similar-doctors mt-4">
                  <a style="text-decoration: none;" routerLinkActive="active" [routerLink]="['/doctor-ajina-detail']">
                    <div class="similar-doc-item">
                      <img src="assets/img/dr_two.jpg" class="img-fluid">
                      <h5>Dr. Ajina M</h5>
                      <span style="color:#000000B2 ;">MBBS DDVL</span>
                    </div>
                  </a>
                 <!-- <a style="text-decoration: none;"  href="">
                    <div class="similar-doc-item mt-5">
                      <img src="assets/img/doc3.png" class="img-fluid">
                      <h5>Dr. Leslie Alexander</h5>
                      <span>MBBS, MD (DVD), DNB, FECSM</span>
                    </div>
                  </a>
                  <a style="text-decoration: none;"  href="">
                    <div class="similar-doc-item mt-5">
                      <img src="assets/img/doc4.png" class="img-fluid">
                      <h5>Dr. John Alexander</h5>
                      <span>MBBS, MD (DVD), DNB, FECSM</span>
                    </div>
                    </a> -->
  
                </div>
            </div>
        </div>
    </div>
  </section>
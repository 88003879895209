import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GalleryService } from 'src/app/provider/gallery.service';
import { HomeService } from 'src/app/provider/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


 
  currentDateTime:any
  myDate = new Date();

  blogList:any=[]
  galleryList:any=[]
  publicationList:any=[]

  doctersOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
  
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  }

  blogsOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
  
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  }

  facilitiesOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
  
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  }


  vedioGallery: OwlOptions = {
    loop: false,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',

    dots: true,
    nav:false,
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    autoplayTimeout: 5000,
    autoplaySpeed: 1000,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
  }

  constructor(public home:HomeService,public gallery:GalleryService,protected sanitizer: DomSanitizer,public datepipe: DatePipe) {
    //  this.currentDateTime =this.datepipe.transform((new Date), 	'EEEE');
    // console.log(this.currentDateTime)

   }

  ngOnInit(): void {
    this.getblogList()
    this.getpublicationList()
    this.getVideoGallery()
  }

  getblogList(){
    this.home.getblogList().subscribe(resp=>{
      if(resp.success=true){
        this.blogList=resp.data.data
      }
    })

  }

  getpublicationList(){
    this.home.getpublicationList().subscribe(resp=>{
      if(resp.success=true){
        this.publicationList=resp.data
      }
    })

  }

  getVideoGallery(){

    this.gallery.getVideoGallery().subscribe((resp:any) => {
      if (resp.success == true) {
        
        this.galleryList =resp.data
        this.galleryList.forEach((element:any) => {
          element.convertedURL=this.getEmbedUrl(element.image);

        });
      }
    })
  }

  getEmbedUrl(url:string) {
    const regExp =  /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);

    const videoId= (match && match[1])  ? match[1]  : null;
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'  + videoId );
  }

}

<div class="bread-crumb mt-5">
    <div class="container">
        <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <li><a routerLinkActive="active" [routerLink]="['/service-list']">Services</a></li>
            <li>General Dermatology</li>
        </ul>
    </div>
</div>


<section class="service-details-area sub-title">
    <div class="container">

        <div class="row">
            <div class="col-lg-8 mt-5">
                <div class="service-details-content">
                    <img src="assets/img/General Dermatology.jpg" class="img-fluid">
                    <h2>General Dermatology</h2>
                    <p>
                        With the help of advanced diagnostic tools, our outstanding certified dermatologists are popular
                        for accurate clinical diagnosis and appropriate management. Our signature nutritional therapy
                        for various skin disorders are well appreciated all over. Our expertise extends beyond the
                        following list:
                    </p>
                    <ul>
                        <li>Psoriasis, Lichen planus, Atopic dermatitis, Eczema,</li>
                        <li>Urticaria, Cutaneous vasculitis, Drug reactions</li>
                        <li>Vesiculobullous disorders</li>
                        <li>Paediatric skin diseases</li>
                        <li>Acne, Acne scar, Rosacea, Hidradinitis suppurativa,</li>
                        <li>Autoimmune disorders affecting skin</li>
                        <li>Geno dermatoses,</li>
                        <li>Skin disorders due to systemic diseases</li>
                        <li>Vitiligo, Melasma, Pigmentation</li>
                        <li>Alopecia areata, Male and female patterned hair loss, telogen effluvium</li>
                        <li>Onychomycosis, Paronychia, Ingrown toenails</li>
                        <li>Oral ulcer, Oral lichen planus, cheilitis</li>
                        <li>Non STD genital disorders</li>
                        <li>Tinea infection, scabies, folliculitis, furunculosis, varicella, herpes zoster, pediculosis.
                        </li>


                    </ul>

                    

                </div>


                <div class="other-datas deskhide">
                    <div class="row">
                        <div class="col-lg-4 mt-4">
                            <img src="assets/img/ACNE.jpg" class="img-fluid">
                            <h5>ACNE</h5>
                        </div>
                        <div class="col-lg-4 mt-4">
                            <img src="assets/img/ALOPECIA AREATA.jpg" class="img-fluid">
                            <h5>ALOPECIA AREATA</h5>
                        </div>
                        <div class="col-lg-4 mt-4">
                            <img src="assets/img/MELASMA.jpg" class="img-fluid">
                            <h5>MELASMA</h5>
                        </div>
                </div>
    
                <div class="row">
                    <div class="col-lg-4 mt-4">
                        <img src="assets/img/PEDIATRIC SKIN DISEASES.png" class="img-fluid">
                        <h5>PEDIATRIC SKIN DISEASES</h5>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <img src="assets/img/PSORIASIS.png" class="img-fluid">
                        <h5>PSORIASIS</h5>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <img src="assets/img/vitiligo.png" class="img-fluid">
                        <h5>vitiligo</h5>
                    </div>
            </div>
                
                </div>


            </div>








            <div class="col-lg-4 mt-5">
                <h5 style="color: #20618E; font-size: 20px;">Other Services</h5>
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/cosmetic-treatment']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/Skin tag - Wart- DPN Removal.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Cosmetic Treatments</h5>

                        </div>


                    </div>
                </a>
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/hair-clinic']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/Hair Transplant.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Hair Clinic</h5>

                        </div>


                    </div>
                </a>
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/psychodermatology']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/PSYCHODERMATOLOGY & PALLIATIVE DERMATOLOGY (2).jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Psychodermatology & Palliative Dermatology</h5>

                        </div>


                    </div>
                </a>

                <a class="service_tag" routerLinkActive="active" [routerLink]="['/sexually-disease']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/5.-What is an unconsummated marriage.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>STD (Sexually Transmitted Disease)</h5>

                        </div>


                    </div>
                </a>
                <a class="service_tag" routerLinkActive="active" [routerLink]="['/amore-centre']">
                    <div class="details-right d-flex mt-4">

                        <div class="details-right-img">
                            <img src="assets/img/amore.jpg">
                        </div>
                        <div class="service-right-details">
                            <h5>Amore Centre For Sexual Medicine And Relationship Therapy</h5>

                        </div>


                    </div>
                </a>


            </div>
        </div>


        <div class="row">
            <div class="other-datas mobhide">
                <div class="row">
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/ACNE.jpg" class="img-fluid">
                        <h5>ACNE</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/ALOPECIA AREATA.jpg" class="img-fluid">
                        <h5>ALOPECIA AREATA</h5>
                    </div>
                    <div class="col-lg-4 other-item mt-4">
                        <img src="assets/img/MELASMA.jpg" class="img-fluid">
                        <h5>MELASMA</h5>
                    </div>
            </div>

            <div class="row">
                <div class="col-lg-4 other-item mt-4">
                    <img src="assets/img/PEDIATRIC SKIN DISEASES.png" class="img-fluid">
                    <h5>PEDIATRIC SKIN DISEASES</h5>
                </div>
                <div class="col-lg-4 other-item mt-4">
                    <img src="assets/img/PSORIASIS.png" class="img-fluid">
                    <h5>PSORIASIS</h5>
                </div>
                <div class="col-lg-4 other-item mt-4">
                    <img src="assets/img/vitiligo.png" class="img-fluid">
                    <h5>vitiligo</h5>
                </div>
        </div>
            
            </div>
        </div>


    </div>
</section>

<section class="faq-home mt-50">
    <div class="container">
        <h2 class="text-center mt-4">FAQ</h2>

        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        1. What services does Revive Skin Clinics offer in general dermatology?                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Revive Skin Clinics in Kozhikode offers a comprehensive range of general dermatology services, including treatment for acne,alopecia areata,melasma,pediatric skin diseases,psoriasis,vitiligo. We also provide skin cancer screenings, mole evaluations, and treatment for various skin allergies and rashes.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2. What should I expect during my first dermatology consultation?                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        During your first consultation at Revive Skin Clinics, the dermatologist will conduct a thorough skin examination, discuss your medical history, and address any specific skin concerns you have. This initial visit may also include recommendations for diagnostic tests or treatments if necessary.</div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        3. Are the treatments at Revive Skin Clinics safe and effective?                    </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Yes, all treatments at Revive Skin Clinics are safe and effective. Our dermatologists use the latest technologies and evidence-based practices to ensure high-quality care. We follow strict safety protocols to provide the best possible outcomes for our patients.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        4. What is the cost of a dermatology consultation at Revive Skin Clinics?                    </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        The cost of a dermatology consultation at Revive Skin Clinics varies depending on the specific services required. We provide transparent pricing and detailed information on costs during the booking process or upon inquiry at our clinic.</div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        5. How can I schedule an appointment at Revive Skin Clinics? </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        To schedule an appointment at Revive Skin Clinics, you can call our clinic directly or use our online booking system available on our website. We recommend booking in advance to ensure availability, especially for consultations with our specialist dermatologists.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cosmetic-treatment',
  templateUrl: './cosmetic-treatment.component.html',
  styleUrls: ['./cosmetic-treatment.component.scss']
})
export class CosmeticTreatmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

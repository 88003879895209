<section id="HEADER" style="background-color: #EBF6E8;">
  
    <nav class="navbar navbar-expand-lg navbar-light"  >
        <div class="container">
          <a class="navbar-brand" href="#">
            <img src="assets/img/logo.png">
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo02" >
            
            <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/home']" >Home</a> </li>
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/about']" >About Us</a> </li>
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/service-list']" >Services</a> </li>
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/facilities']" >Facilities</a> </li>
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/video-gallery']" >Video Gallery</a> </li>
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/publication']" >Publications</a> </li>
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/blogs']" >Blogs</a> </li>
                <li class="nav-item" > <a class="nav-link" routerLinkActive="active" [routerLink]="['/contact']" >Contact Us</a> </li>
            </ul>
            
          </div>
        </div>
      </nav>
   
  </section>



 
